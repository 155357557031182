import { Box, IconButton, Typography, Avatar, Badge, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { Grey900, Green600, Green50 } from '../../consts/colors';
import SeeProjectDetailsStyles from '../../pages/SeeProjectDetails/SeeProjectDetailsStyles';
import { formatDate } from '../../utils/dateUtils';
import { BlueCheck, EditProjectIcon, StartDateGreenIcon, ThreeDots } from '../../assets/icons';
import { styles } from './styles';
import ActionItemModal from './ActionItemModal';
import dayjs from 'dayjs';

export default function ActionItem({ item, users, updateItem, deleteItem, questions }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const openMenu = Boolean(anchorEl);

    // Find the assignee from users list
    const assignee = users.find(user => user.user_id === item.assignee_id) || item.responsible;
    const assigneeName = assignee && assignee.first_name ? `${assignee.first_name} ${assignee.last_name}` : (assignee ? assignee : 'Unassigned');
    const assigneeInitials = assignee && assignee.first_name ? 
        `${assignee.first_name[0]}${assignee.last_name[0]}` : (assignee ? assignee.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase() : 'UA');

    // Find the question from questions list
    const question = questions.find(q => q.question_id === item.question_id);
    const questionText = question ? question.value_question  : 'No question assigned';

    // Format the date
    const formattedDate = item.due_date ? dayjs(item.due_date).format('MMM DD YYYY') : 'No date set';

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMarkAsDone = () => {
        updateItem({ ...item, status: 'Closed' });
        handleMenuClose();
    };

    const handleEdit = () => {
        setShowEditModal(true);
        handleMenuClose();
    };

    const handleEditModalClose = () => {
        setShowEditModal(false);
    };

    const handleSaveEdit = (editedData) => {
        updateItem({ ...item, ...editedData });
        setShowEditModal(false);
    };

    const renderSettingsMenu = (icon, title, onClick) => {
        return (
            <MenuItem
                key={title}
                onClick={onClick}
                disableRipple
                sx={{
                    '&:hover': {
                        backgroundColor: Green50
                    }
                }}
            >
                {icon}
                <Typography sx={styles.typographyMenuOptions}>{title}</Typography>
            </MenuItem>
        );
    };

    const renderDetailsCards = (title, avatar, value) => {
        return (
            <Box>
                <Typography sx={{...SeeProjectDetailsStyles.details, fontSize: '13px'}}>{title}</Typography>
                {title === 'Status' ? (
                    <Badge
                        badgeContent={value}
                        color="secondary"
                        sx={{
                            marginLeft: '20px',
                            '& .MuiBadge-badge': {
                                fontSize: '12px',
                                height: '20px',
                                minWidth: '20px',
                                padding: '0 6px',
                                borderRadius: '10px',
                                backgroundColor: item.status === 'Open' ? Green600 : Grey900,
                            },
                        }}
                    />
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {avatar ? (
                            avatar
                        ) : (
                            <Avatar 
                                variant="circle" 
                                sx={{ 
                                    ...SeeProjectDetailsStyles.avatarCircle, 
                                    width: 25, 
                                    height: 25,
                                    bgcolor: Green600
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    color="inherit"
                                    sx={{ ...SeeProjectDetailsStyles.typographyPr, fontSize: '9px' }}
                                >
                                    {assigneeInitials}
                                </Typography>
                            </Avatar>
                        )}

                        <Typography
                            sx={{
                                ...SeeProjectDetailsStyles.titleDescription,
                                fontSize: '15px',
                                padding: '0px',
                                marginLeft: '5px',
                                color: Grey900,
                            }}
                        >
                            {value}
                        </Typography>
                    </Box>
                )}
            </Box>
        );
    };

    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                margin={2}
                padding={3}
                gap={1}
                style={{ border: '1px solid #EEF2F6', borderRadius: '15px', backgroundColor: '#E3E8EF' }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
                    <Box sx={{ display: 'flex', gap: '80px', margin: '10px' }}>
                        {renderDetailsCards('Assignee', null, assigneeName)}
                        {renderDetailsCards('Due Date', <StartDateGreenIcon />, formattedDate)}
                        {renderDetailsCards('Status', null, item.status)}
                    </Box>
                    <IconButton
                        sx={{ position: 'absolute', top: 1, right: 1 }}
                        onClick={handleMenuOpen}
                    >
                        <ThreeDots />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleMenuClose}
                        MenuListProps={{
                            style: {
                                maxHeight: 48 * 4.5,
                                width: '150px',
                                borderRadius: '10px',
                            },
                        }}
                    >
                        {item.status === 'Open' && renderSettingsMenu(
                            <BlueCheck width={'15px'} height={'15px'} sx={{ marginRight: '10px'}} />, 
                            'Mark as Done',
                            handleMarkAsDone
                        )}
                        {renderSettingsMenu(
                            <EditProjectIcon width={'15px'} height={'15px'} sx={{ marginRight: '10px'}} />, 
                            'Edit',
                            handleEdit
                        )}
                    </Menu>
                </Box>
                <Typography sx={{...SeeProjectDetailsStyles.details, fontSize: '13px', marginLeft: '5px'}}>Question</Typography>
                <Typography
                    sx={{
                        ...SeeProjectDetailsStyles.titleDescription,
                        fontSize: '15px',
                        padding: '0px',
                        marginLeft: '5px',
                        color: Grey900,
                    }}
                >
                    {questionText}
                </Typography>
                {item.comment && (
                    <>
                        <Typography sx={{...SeeProjectDetailsStyles.details, fontSize: '13px', marginLeft: '5px'}}>Comment</Typography>
                        <Typography
                            sx={{
                                ...SeeProjectDetailsStyles.titleDescription,
                                fontSize: '15px',
                                padding: '0px',
                                marginLeft: '5px',
                                color: Grey900,
                                fontWeight: 400,
                            }}
                        >
                            {item.comment}
                        </Typography>
                    </>
                )}
            </Box>
            
            <ActionItemModal 
                isOpen={showEditModal}
                closeModal={handleEditModalClose}
                users={users}
                questions={questions}
                saveActionItem={handleSaveEdit}
                initialData={{
                    description: item.description,
                    assignee_id: item.assignee_id,
                    due_date: item.due_date ? dayjs(item.due_date) : null,
                    question_id: item.question_id,
                    comment: item.comment
                }}
                isEditing={true}
            />
        </>
    );
}
