import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, Select, MenuItem } from '@mui/material';
import moment from 'moment';
import SeeProjectDetailsStyles from '../SeeProjectDetailsStyles';
import { Green300, RedAlert, Yellow500, Green400 } from '../../../consts/colors';
import ActionItems from '../../../components/actionItems/ActionItems';
import { useParams } from 'react-router-dom';
import { defaultValues } from '../utils';
import { LastReviewClockIcon, NeedsDiscussionIcon, ProjectRiskIcon, QuestionsIcon } from '../../../assets/icons';
import CommentsCard from '../CommentsCard';
import Placeholder from '../../../components/Placeholder';
import axiosFetch from '../../../api/Axios';

const ExecutiveSummary = ({ projectDetails, users, hasData, handleFieldSave, questions }) => {
    const [selectedValue, setSelectedValue] = useState(0);
    const [managerComments, setManagerComments] = useState([]);
    const itemId = useParams().itemId;
    const [error, setError] = useState(null);

    const getScoreColor = (row) => {        
        if (row.response_score === 0) {
            return 'gray';
        } else if (row.response_score < 3) {
            return 'red';
        } else if (row.response_score >= 3 && row.response_score < 4) {
            return 'yellow'
        }
        return 'green';
    };

    let redQuestionsNumber = 0;
    if (questions) {
        redQuestionsNumber = questions.reduce((acc, obj) => getScoreColor(obj) === 'red' ? acc + 1 : acc, 0)
    }

    const renderTitleCard = (title) => {
        return <Typography sx={SeeProjectDetailsStyles.titleDescription}>{title}</Typography>;
    };
    const fetchLastReview = async () => {
        if (!itemId) {
            setError('No project ID provided');
            return;
        }

        try {
            setError(null);
            const reviews = await axiosFetch({
                url: `${process.env.REACT_APP_API_PORT}project/${itemId}/reviews`,
                method: 'GET',
            });

            if (!reviews || reviews.length === 0) {
                setManagerComments([]);
                return;
            }

            // Map reviews to include user information
            const reviewsWithUsers = reviews.map((review) => {
                const user = users.find((u) => u.user_id === review.user_id);
                return {
                    review_id: review.review_id,
                    date: review.created_date,
                    manager_name: user ? `${user.first_name} ${user.last_name}` : 'Unknown User',
                    comment: review.manager_comment,
                };
            });
            setManagerComments(reviewsWithUsers);
        } catch (error) {
            console.error('Error in fetchLastReview:', error);
            setError(error.response?.data?.message || 'Error loading review data');
        }
    };

    useEffect(() => {
        if (itemId) {
            fetchLastReview();
        }
        return () => {
            // Cleanup on unmount or when projectId changes
            setError(null);
        };
    }, [itemId]);

    const filteredComments = selectedValue === 1 && managerComments.length > 0 ? [managerComments[0]] : managerComments;

    return (
        <Box>
            <Box sx={SeeProjectDetailsStyles.main}>
                <Box sx={SeeProjectDetailsStyles.projectStatusCardWidth}>
                    <Card elevation={3} sx={SeeProjectDetailsStyles.status}>
                        <CardContent>
                            <Box sx={SeeProjectDetailsStyles.titleBox}>{renderTitleCard('Project Status')}</Box>
                            <Box sx={SeeProjectDetailsStyles.inlineStatusFields}>
                                <Box sx={SeeProjectDetailsStyles.projectStatusInlineBoxes}>
                                    <Typography
                                        sx={{
                                            ...SeeProjectDetailsStyles.mainStatusField,
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            gap: '4px',
                                        }}
                                    >
                                        <LastReviewClockIcon /> Last Review
                                    </Typography>
                                    {hasData ? (
                                        <Typography sx={SeeProjectDetailsStyles.statusFields}>
                                            {moment(new Date()).diff(moment(projectDetails.created_date), 'days')} day(s) ago
                                        </Typography>
                                    ) : (
                                        <Typography sx={SeeProjectDetailsStyles.projectStatusDefaultText}>
                                            {' '}
                                            {defaultValues.last_review}
                                        </Typography>
                                    )}
                                </Box>
                                <Box sx={SeeProjectDetailsStyles.projectStatusInlineBoxes}>
                                    <Typography
                                        sx={{
                                            ...SeeProjectDetailsStyles.mainStatusField,
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            gap: '4px',
                                        }}
                                    >
                                        <ProjectRiskIcon /> Project Risk
                                    </Typography>
                                    {hasData ? (
                                        <Select
                                            value={projectDetails.risk_level}
                                            onChange={(event) => handleFieldSave(event.target.value, 'risk_level')}
                                            sx={{
                                                ...SeeProjectDetailsStyles.projectStatusDefaultText,
                                                ...SeeProjectDetailsStyles.selectField,
                                            }}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without border' }}
                                        >
                                            {[
                                                { value: 0, label: 'Green', color: Green300 },
                                                { value: 1, label: 'Yellow', color: Yellow500 },
                                                { value: 2, label: 'Red', color: RedAlert },
                                            ].map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '5px',
                                                    }}
                                                >
                                                    {/* Color Circle */}
                                                    <div
                                                        style={{
                                                            width: '12px',
                                                            height: '12px',
                                                            borderRadius: '50%',
                                                            backgroundColor: option.color,
                                                        }}
                                                    ></div>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    ) : (
                                        <Typography sx={SeeProjectDetailsStyles.projectStatusDefaultText}>{defaultValues.risk}</Typography>
                                    )}
                                </Box>
                                <Box sx={SeeProjectDetailsStyles.projectStatusInlineBoxes}>
                                    <Typography
                                        sx={{
                                            ...SeeProjectDetailsStyles.mainStatusField,
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            gap: '4px',
                                        }}
                                    >
                                        <NeedsDiscussionIcon /> Needs Discussion
                                    </Typography>
                                    {hasData ? (
                                        <Select
                                            value={projectDetails.needs_attention ? 1 : 0}
                                            onChange={(event) => handleFieldSave(event.target.value, 'needs_attention')}
                                            sx={{
                                                ...SeeProjectDetailsStyles.projectStatusDefaultText,
                                                ...SeeProjectDetailsStyles.selectField,
                                                width: '85px',
                                            }}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without border' }}
                                        >
                                            {[
                                                { value: 1, label: 'Yes', color: RedAlert },
                                                { value: 0, label: 'No', color: Green300 },
                                            ].map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '5px',
                                                    }}
                                                >
                                                    {/* Color Circle */}
                                                    <div
                                                        style={{
                                                            width: '12px',
                                                            height: '12px',
                                                            borderRadius: '50%',
                                                            backgroundColor: option.color,
                                                        }}
                                                    ></div>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    ) : (
                                        <Typography sx={SeeProjectDetailsStyles.projectStatusDefaultText}>
                                            {' '}
                                            {defaultValues.needs_discussion}
                                        </Typography>
                                    )}
                                </Box>
                                <Box sx={SeeProjectDetailsStyles.projectStatusInlineBoxes}>
                                    <Typography
                                        sx={{
                                            ...SeeProjectDetailsStyles.mainStatusField,
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            gap: '4px',
                                        }}
                                    >
                                        <QuestionsIcon style={{width: '14.33px', height: '14.33px'}}/> Red Questions
                                    </Typography>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px',
                                    }}>
                                        <div
                                            style={{
                                                width: '12px',
                                                height: '12px',
                                                borderRadius: '50%',
                                                backgroundColor: redQuestionsNumber === 0 ? Green300 : RedAlert,
                                            }}
                                        ></div>
                                        <Typography sx={SeeProjectDetailsStyles.statusFields}>
                                            {redQuestionsNumber}
                                        </Typography>
                                    </div>
                                    
                                </Box>
                            </Box>
                            <Placeholder height={'25px'} />
                            <Box sx={{ ...SeeProjectDetailsStyles.commentsCardHeader, alignItems: 'none' }}>
                                {renderTitleCard('Manager Comment')}
                                <Select
                                    value={selectedValue}
                                    onChange={(event) => setSelectedValue(event.target.value)}
                                    sx={{
                                        ...SeeProjectDetailsStyles.projectStatusDefaultText,
                                        ...SeeProjectDetailsStyles.selectField,
                                        color: Green400,
                                    }}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without border' }}
                                >
                                    {[
                                        { value: 0, label: 'Show All' },
                                        { value: 1, label: 'Latest' },
                                    ].map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '5px',
                                            }}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>

                            {filteredComments.map((comment, index) => (
                                <CommentsCard key={index} data={comment} />
                            ))}
                        </CardContent>
                    </Card>
                </Box>
                <Box sx={SeeProjectDetailsStyles.actionItemsCardWidth}>
                    <Card elevation={3} sx={SeeProjectDetailsStyles.rhistory}>
                        <ActionItems users={users} projectId={itemId} />
                    </Card>
                </Box>
            </Box>
        </Box>
    );
};

export default ExecutiveSummary;
