import { MenuItem, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosFetch from '../../api/Axios';
import Save from '../../assets/icons/save.svg';
import GreenButtonED from '../../components/GreenButtonED';
import LoadingComponent from '../../components/LoadingComponent';
import SnackBar from '../../components/SnackBar';
import WrapperPage from '../../components/WrapperPage';
import { Green600 } from '../../consts/colors';
import { NewProjectStyles } from '../NewProject/styles';

function EditUserPage({ sidebarWidth }) {
    const [firstName, setFirstName] = useState('');
    const [secondName, setSecondName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [directManager, setDirectManager] = useState('');
    const [notification, setNotification] = useState('');
    const [deactivate, setDeactivate] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [options, setOptions] = useState([]);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const { userId } = useParams();
    const [userData, setUserData] = useState(null);
    const [initialUserName, setInitialUserName] = useState([]);
    const [isLoadingUserData, setIsLoadingUserData] = useState(false);
    const [isLoadingManagerData, setIsLoadingManagerData] = useState(false);

    useEffect(() => {
        fetchUsersData(userId);
        fetchManagerData();
    }, [userId]);

    const fetchManagerData = async () => {
        try {
            setIsLoadingManagerData(true);
            const response = await axiosFetch({
                method: 'GET',
                url: process.env.REACT_APP_API_PORT + 'users',
            });
            setOptions(response);
        } catch (error) {
            console.log('Error: ', error);
            setError({
                message: error.message,
                severity: 'error',
            });
        } finally {
            setIsLoadingManagerData(false);
        }
    };

    const fetchUsersData = async (userId) => {
        try {
            setIsLoadingUserData(true);
            const response = await axiosFetch({
                method: 'GET',
                url: process.env.REACT_APP_API_PORT + 'user/' + userId,
            });
            if (response && Object.keys(response).length > 0) {
                setUserData(response);
                setFirstName(response.first_name || '');
                setSecondName(response.last_name || '');
                setEmail(response.email || '');
                setRole(response.user_role || '');
                setDirectManager(response.manager_key || '');
                setNotification(response.notification_active);
                setDeactivate(response.deactivate_user);
                setInitialUserName(response.first_name + ' ' + response.last_name || '');
            } else {
                console.log('No project data found.');
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            setIsLoadingUserData(false);
        }
    };

    const handleSaveClick = async () => {
        const dataToSend = {
            email: email,
            first_name: firstName,
            last_name: secondName,
            user_role: role,
            notification_active: notification,
            manager_key: directManager,
            deactivate_user: deactivate,
        };

        const options = {
            url: process.env.REACT_APP_API_PORT + 'user/' + userId,
            method: 'PUT',
            data: dataToSend,
        };

        try {
            const responseData = await axiosFetch(options);
            if (responseData) {
                fetchUsersData(userId);
                fetchManagerData();
                setSuccess(true);
            } else setSuccess(false);
        } catch (error) {
            setError({
                message: error.message,
                severity: 'error',
            });
        }
        setIsFormDirty(false);
    };

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
        setIsFormDirty(true);
    };
    const handleSecondNameChange = (event) => {
        setSecondName(event.target.value);
        setIsFormDirty(true);
    };
    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
        setIsFormDirty(true);
    };

    const validateEmail = () => {
        const emailPattern = /@vspartners\.us$/i;
        setIsFormDirty(true);
        if (emailPattern.test(email)) {
            setError(null);
            setSuccess(false);
        } else {
            setError({
                message: 'The email should belong to the domain.ceva domain!',
                severity: 'error',
            });
            setSuccess(false);
        }
    };

    const handleRoleChange = (event) => {
        setRole(event.target.value);
        setIsFormDirty(true);
    };
    const handleDirectManagerChange = (event) => {
        setDirectManager(event.target.value);
        setIsFormDirty(true);
    };
    const handleNotificationChange = (event) => {
        setNotification(event.target.checked);
        setIsFormDirty(true);
    };
    const handleDeactivateChange = (event) => {
        setDeactivate(!event.target.checked);
        setIsFormDirty(true);
    };

    const handleSuccessSnackbarClose = () => {
        setSuccess(false);
    };

    return (
        <WrapperPage sidebarWidth={sidebarWidth}>
            {isLoadingUserData || isLoadingManagerData ? (
                <LoadingComponent />
            ) : (
                <React.Fragment>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: '36px',
                        }}
                    >
                        <h1 style={{ ...NewProjectStyles.textTitle, margin: 0 }}>Edit {initialUserName} Details</h1>
                        <GreenButtonED
                            iconSrc={Save}
                            buttonText="Save Changes"
                            width="206px"
                            height="44px"
                            fontSize={14}
                            lineHeight={18}
                            type="button"
                            onClick={handleSaveClick}
                            disabled={!isFormDirty}
                            style={{ flex: '0 0 auto' }}
                        />
                    </div>
                    {userData && (
                        <form>
                            <div style={{ marginBottom: '20px' }}>
                                <span style={{ ...NewProjectStyles.numbers }}>1</span>
                                <span style={{ ...NewProjectStyles.text }}>First Name</span>
                            </div>
                            <TextField
                                type="text"
                                variant="outlined"
                                color="secondary"
                                label="Type user's first name"
                                onChange={handleFirstNameChange}
                                value={firstName}
                                fullWidth
                                required
                                sx={{ mb: 4, marginLeft: '20px' }}
                            />

                            <div style={{ marginBottom: '20px' }}>
                                <span style={{ ...NewProjectStyles.numbers }}>2</span>
                                <span style={{ ...NewProjectStyles.text }}>Last Name</span>
                            </div>
                            <TextField
                                type="text"
                                variant="outlined"
                                color="secondary"
                                label="Type user's last name"
                                onChange={handleSecondNameChange}
                                value={secondName}
                                fullWidth
                                required
                                sx={{ mb: 4, marginLeft: '20px' }}
                            />

                            <div style={{ marginBottom: '20px' }}>
                                <span style={{ ...NewProjectStyles.numbers }}>3</span>
                                <span style={{ ...NewProjectStyles.text }}>Email</span>
                            </div>
                            <TextField
                                type="text"
                                variant="outlined"
                                color="secondary"
                                label="Type user's email"
                                onChange={handleEmailChange}
                                onBlur={validateEmail}
                                value={email}
                                fullWidth
                                required
                                sx={{ mb: 4, marginLeft: '20px' }}
                            />

                            <div style={{ marginBottom: '20px' }}>
                                <span style={{ ...NewProjectStyles.numbers }}>4</span>
                                <span style={{ ...NewProjectStyles.text }}>Role</span>
                            </div>
                            <FormControl sx={{ width: '100%', mb: 4, marginLeft: '20px' }}>
                                <InputLabel id="role-label">Pick Role</InputLabel>
                                <Select labelId="role-label" id="role-select" value={role} onChange={handleRoleChange} label="Pick Role">
                                    <MenuItem value={'Manager'}>Manager</MenuItem>
                                    <MenuItem value={'Admin'}>Admin</MenuItem>
                                    <MenuItem value={'Viewer'}>Viewer</MenuItem>
                                </Select>
                            </FormControl>

                            <div style={{ marginBottom: '20px' }}>
                                <span style={{ ...NewProjectStyles.numbers }}>5</span>
                                <span style={{ ...NewProjectStyles.text }}>Manager</span>
                            </div>
                            <FormControl sx={{ width: '100%', mb: 4, marginLeft: '20px' }}>
                                <InputLabel id="manager-label">Pick Manager</InputLabel>
                                <Select
                                    labelId="manager-label"
                                    id="manager-select"
                                    value={directManager}
                                    onChange={handleDirectManagerChange}
                                    label="Pick Manager"
                                >
                                    {options.map((option) => (
                                        <MenuItem key={option.user_id} value={option.last_name}>
                                            {option.first_name} {option.last_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <div style={{ marginBottom: '20px' }}>
                                <span style={{ ...NewProjectStyles.numbers }}>6</span>
                                <span style={{ ...NewProjectStyles.text }}>Notifications Status</span>
                            </div>
                            <FormControlLabel
                                sx={{ width: '100%', mb: 4, marginLeft: '20px' }}
                                control={
                                    <Checkbox
                                        sx={{
                                            '&.Mui-checked': {
                                                color: Green600,
                                            },
                                        }}
                                        checked={notification}
                                        onChange={handleNotificationChange}
                                    />
                                }
                                label={
                                    <Typography variant="body1" style={{ ...NewProjectStyles.text }}>
                                        {notification ? 'Enabled' : 'Disabled'}
                                    </Typography>
                                }
                            />

                            <div style={{ marginBottom: '20px' }}>
                                <span style={{ ...NewProjectStyles.numbers }}>7</span>
                                <span style={{ ...NewProjectStyles.text }}>User Status</span>
                            </div>
                            <FormControlLabel
                                sx={{ width: '100%', mb: 4, marginLeft: '20px' }}
                                control={
                                    <Checkbox
                                        sx={{
                                            '&.Mui-checked': {
                                                color: Green600,
                                            },
                                        }}
                                        checked={!deactivate}
                                        onChange={handleDeactivateChange}
                                    />
                                }
                                label={
                                    <Typography variant="body1" style={{ ...NewProjectStyles.text }}>
                                        {deactivate ? 'Disabled' : 'Enabled'}
                                    </Typography>
                                }
                            />
                        </form>
                    )}

                    {error?.message && (
                        <SnackBar open={true} message={error.message} severity={error.severity} handleClose={() => setError(null)} />
                    )}

                    {success && (
                        <SnackBar
                            open={true}
                            message="Form submitted successfully!"
                            severity="success"
                            handleClose={handleSuccessSnackbarClose}
                        />
                    )}
                </React.Fragment>
            )}
        </WrapperPage>
    );
}

export default EditUserPage;
