import {
  Blue100,
  Blue400,
  Blue800,
  Black400,
  Green300,
} from "../../consts/colors";

export const styles = {
  container: {
    mb: 3
  },
  card: {
    borderRadius: '10px',
    backgroundColor: 'white',
    marginRight: '2%',
  },
  content: {
    padding: '24px !important'
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 600,
    color: '#2D3748',
    marginBottom: '24px'
  },
  noDataText: {
    mt: 2,
    color: 'text.secondary'
  },
  questionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  questionAccordion: {
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      backgroundColor: '#4CAF50',
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },
    position: 'relative',
    borderRadius: '8px',
    boxShadow: 'none',
    border: '1px solid #E2E8F0',
    overflow: 'hidden',
    '& .MuiAccordionSummary-expandIconWrapper': {
      position: 'absolute',
      right: '16px'
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-root': {
      minHeight: '48px',
      '&.Mui-expanded': {
        minHeight: '48px',
      }
    },
    '& .MuiAccordionSummary-content': {
      margin: '12px 0',
      '&.Mui-expanded': {
        margin: '12px 0'
      }
    }
  },
  questionAccordionSummary: {
    padding: '0 48px 0 16px',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)'
    }
  },
  questionContainer: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '250px 1fr 1fr',
    alignItems: 'center',
    gridAutoFlow: 'column'
  },
  questionText: {
    fontSize: '14px',
    color: '#2D3748',
    fontWeight: 500
  },
  metricsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'center'
  },
  metricLabel: {
    color: '#718096',
    fontSize: '14px'
  },
  metricAvatar: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    fontWeight: 500
  },
  activeMetricAvatar: {
    backgroundColor: '#48BB78',
    color: 'white'
  },
  inactiveMetricAvatar: {
    backgroundColor: '#E2E8F0',
    color: '#718096'
  },
  accordionDetails: {
    padding: '16px',
    backgroundColor: '#F7FAFC'
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  responseText: {
    fontSize: '14px',
    color: 'text.primary'
  },
  scoreText: {
    fontSize: '14px',
    color: 'text.secondary'
  },
  noteText: {
    fontSize: '14px',
    color: 'text.secondary'
  }
};
