import { useState, useEffect } from 'react';
import axiosFetch from '../api/Axios';
import { ENDPOINTS } from '../api/endpoints';
import { useSnackbar } from './useSnackbar';

export const useActionItems = (projectId) => {
    const [actionItems, setActionItems] = useState([]);
    const [openActionItems, setOpenActionItems] = useState([]);
    const [closedActionItems, setClosedActionItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const { showSnackbar } = useSnackbar();

    const updateActionItemsState = (items) => {
        setActionItems(items);
        setOpenActionItems(items.filter((item) => item.status !== 'Closed'));
        setClosedActionItems(items.filter((item) => item.status === 'Closed'));
    };

    useEffect(() => {
        const getActionItems = async () => {
            if (!projectId) {
                showSnackbar('Project ID is required', 'error');
                setLoading(false);
                return;
            }

            try {
                const response = await axiosFetch({
                    method: 'GET',
                    url: ENDPOINTS.ACTION_ITEMS.LIST(projectId),
                });
                updateActionItemsState(response);
            } catch (error) {
                const message = error.response?.status === 404 
                    ? 'Project not found' 
                    : 'Error fetching action items';
                showSnackbar(message, 'error');
                updateActionItemsState([]);
            } finally {
                setLoading(false);
            }
        };

        getActionItems();
    }, [projectId, showSnackbar]);

    const addActionItem = async (formData, currentUserId) => {
        const newItem = {
            project_id: projectId,
            user_id: currentUserId,
            status: 'Open',
            is_deleted: false,
            description: formData.description,
            assignee_id: formData.assignee_id,
            due_date: formData.due_date,
            question_id: formData.question_id,
            comment: formData.comment
        };

        try {
            const response = await axiosFetch({
                method: 'POST',
                data: newItem,
                url: ENDPOINTS.ACTION_ITEMS.CREATE,
            });

            const formattedResponse = {
                ...response,
                due_date: response.due_date ? response.due_date.split('T')[0] : null
            };

            updateActionItemsState([formattedResponse, ...actionItems]);
            showSnackbar('Action item added successfully', 'success');
            return true;
        } catch (error) {
            showSnackbar('Error creating action item', 'error');
            return false;
        }
    };

    const updateActionItem = async (item) => {
        const updatedItem = {
            action_item_id: item.action_item_id,
            project_id: item.project_id,
            user_id: item.user_id,
            status: item.status,
            is_deleted: item.is_deleted || false,
            description: item.description,
            assignee_id: item.assignee_id,
            due_date: item.due_date,
            question_id: item.question_id,
            comment: item.comment
        };

        try {
            await axiosFetch({
                method: 'PUT',
                data: updatedItem,
                url: ENDPOINTS.ACTION_ITEMS.UPDATE(item.action_item_id),
            });

            const updatedItems = actionItems.map((a) => 
                a.action_item_id === item.action_item_id ? updatedItem : a
            );
            
            updateActionItemsState(updatedItems);
            showSnackbar('Action item updated successfully', 'success');
            return true;
        } catch (error) {
            showSnackbar('Error updating action item', 'error');
            return false;
        }
    };

    const deleteActionItem = async (actionItemId) => {
        try {
            await axiosFetch({
                method: 'DELETE',
                url: ENDPOINTS.ACTION_ITEMS.DELETE(actionItemId),
            });

            const filteredItems = actionItems.filter(a => a.action_item_id !== actionItemId);
            updateActionItemsState(filteredItems);
            showSnackbar('Action item deleted successfully', 'success');
            return true;
        } catch (error) {
            showSnackbar('Error deleting action item', 'error');
            return false;
        }
    };

    return {
        actionItems,
        openActionItems,
        closedActionItems,
        loading,
        addActionItem,
        updateActionItem,
        deleteActionItem
    };
}; 