import { Avatar, Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosFetch from '../../api/Axios';
import submitArrow from '../../assets/icons/submitArrow.svg';
import GreenButtonED from '../../components/GreenButtonED';
import LoadingComponent from '../../components/LoadingComponent';
import { ProjectCardStyle } from '../../components/projectsCard/ProjectCardStyles';
import ManagerComment from '../../components/reviewBox/ManagerComment';
import { NeedsAttentionBox } from '../../components/reviewBox/NeedsAttentionBox';
import ReviewBox from '../../components/reviewBox/ReviewBox';
import { RiskLevelBox } from '../../components/reviewBox/RiskLevelBox';
import SnackBar from '../../components/SnackBar';
import WrapperPage from '../../components/WrapperPage';
import { Green600, RedAlert } from '../../consts/colors';
import { useNavBlocker } from '../../utils/history';
import { NewProjectStyles } from '../NewProject/styles';
import styles from './styles';

const ProjectReviewPage = ({ sidebarWidth }) => {
    const { itemId } = useParams();
    const [projectTitle, setProjectTitle] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [note, setNote] = useState('');
    const [needsAttention, setNeedsAttention] = useState(null);
    const [riskLevel, setRiskLevel] = useState(null);
    const [clientNote, setClientNote] = useState('');
    const [questions, setQuestions] = useState([]);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [isLoadingProjectsData, setIsLoadingProjectsData] = useState(false);
    const [isLoadingOwnersData, setIsLoadingOwnersData] = useState(false);
    const [isLoadingQuestionsData, setIsLoadingQuestionsData] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString('en-GB'));

    const isDirtyRef = useRef(isFormDirty);
    useNavBlocker(isDirtyRef);
    const navigate = useNavigate();

    const handleSaveClick = async () => {
        let missingAnswerFound = false;
        let firstIncompleteFieldRef;

        if (questions.length !== answers.length) {
            questions.forEach((question, index) => {
                const missingAnswer = answers.find((answer) => answer.question_id === question.question_id);

                if (!missingAnswer) {
                    if (!missingAnswerFound) {
                        firstIncompleteFieldRef = `section-${index + 1}`;
                        missingAnswerFound = true;
                    }
                }
            });
        }

        const errorTextDivs = document.querySelectorAll('.error-text');
        errorTextDivs.forEach((div) => div.remove());

        if (missingAnswerFound) {
            const element = document.getElementById(firstIncompleteFieldRef);

            if (element) {
                const errorText = document.createElement('div');
                errorText.classList.add('error-text');
                errorText.innerText = '*This field is required';

                errorText.style.color = RedAlert;
                errorText.style.fontSize = '14px';
                errorText.style.fontFamily = 'poppins';

                element.firstChild.childNodes[2].childNodes[1].classList.add('error');
                element.firstChild.childNodes[2].appendChild(errorText);

                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });

                return;
            }
        }

        const apiPayload = answers.map((answer) => ({
            responseId: answer.response_id,
            quickNote: answer.quick_note !== null ? answer.quick_note : '',
        }));
        const dataToSend = {
            project_id: parseInt(itemId, 10),
            user_id: currentUser.user_id,
            manager_comment: note,
            responses: apiPayload,
            client_partner_comment: clientNote,
            needs_attention: needsAttention,
            risk_level: riskLevel,
        };
        console.log('Data to send: ', dataToSend);
        const options = {
            url: process.env.REACT_APP_API_PORT + 'review',
            method: 'post',
            data: dataToSend,
        };
        setIsSaving(true);
        try {
            const responseData = await axiosFetch(options);
            if (responseData) {
                setSuccess(true);
                setTimeout(() => {
                    navigate(-1);
                }, 1000);
            } else {
                setSuccess(false);
            }
        } catch (error) {
            setError({
                message: error.message,
                severity: 'error',
            });
        }

        setIsFormDirty(false);
        setIsSaving(false);
    };

    const updateAnswer = (questionId, quickNote, responseId) => {
        setAnswers((prevAnswers) => {
            const updatedAnswers = prevAnswers.map((answer) => {
                if (answer.question_id === questionId) {
                    return {
                        ...answer,
                        quick_note: quickNote,
                        response_id: responseId,
                    };
                }
                return answer;
            });

            const isNewQuestion = !prevAnswers.some((answer) => answer.question_id === questionId);
            if (isNewQuestion) {
                updatedAnswers.push({
                    question_id: questionId,
                    quick_note: quickNote,
                    response_id: responseId,
                });
            }

            setIsFormDirty(true);
            return updatedAnswers;
        });
    };

    const handleNoteChange = (event) => {
        setNote(event.target.value);
        setIsFormDirty(true);
    };
    const handleClientChange = (event) => {
        setClientNote(event.target.value);
        setIsFormDirty(true);
    };

    const handleNeedManagementChange = (event) => {
        setNeedsAttention(event.target.value);
        setIsFormDirty(true);
    };

    const handleRiskLevelChange = (event) => {
        setRiskLevel(event.target.value);
        setIsFormDirty(true);
    };

    const handleSuccessSnackbarClose = () => {
        setSuccess(false);
    };

    const getCurrentUser = async () => {
        try {
            const response = await axiosFetch({
                method: 'GET',
                url: process.env.REACT_APP_API_PORT + 'users/me',
            });
            setCurrentUser(response);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoadingProjectsData(true);
                const projectResponse = await axiosFetch({
                    method: 'GET',
                    url: process.env.REACT_APP_API_PORT + 'project/' + itemId,
                });
                setProjectTitle(projectResponse.name || '');
            } catch (error) {
                console.error('An error occurred:', error);
            } finally {
                setIsLoadingProjectsData(false);
                setIsLoadingOwnersData(false);
            }
        }
        fetchData();
        fetchQData();
        getCurrentUser();
    }, [itemId]);

    async function fetchQData() {
        try {
            setIsLoadingQuestionsData(true);
            const response = await axiosFetch({
                method: 'GET',
                url: process.env.REACT_APP_API_PORT + 'questions/active',
            });
            const questions_data = response.map((item) => ({
                question_id: item.question_id,
                value: item.value_question,
                description: item.description
            }));
            setQuestions(questions_data);
        } catch (error) {
            console.error('Error fetching questions:', error);
        } finally {
            setIsLoadingQuestionsData(false);
        }
    }

    return (
        <WrapperPage sidebarWidth={sidebarWidth}>
            {isLoadingOwnersData || isLoadingProjectsData ? (
                <LoadingComponent />
            ) : (
                <React.Fragment>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '36px' }}>
                        <Avatar variant="square" sx={ProjectCardStyle.avatarProject}>
                            <Typography variant="subtitle1" color="inherit" sx={ProjectCardStyle.typographyPr}>
                                {typeof projectTitle === 'string' &&
                                    projectTitle
                                        .split(' ')
                                        .map(function (str) {
                                            return str ? str[0].toUpperCase() : '';
                                        })
                                        .slice(0, 2)
                                        .join('')}
                            </Typography>
                        </Avatar>
                        <h1
                            style={{
                                ...NewProjectStyles.textTitle,
                                margin: 0,
                                marginLeft: '10px',
                            }}
                        >
                            {projectTitle} Review
                        </h1>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h1 style={{ ...styles.header, marginRight: '10px' }}>{`${currentUser.first_name} ${currentUser.last_name}`}</h1>
                        <h1 style={{ ...styles.header, marginRight: '10px' }}>|</h1>
                        <h1 style={{ ...styles.header, marginRight: '10px' }}>{currentDate}</h1>
                    </div>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <div id="section-manager-comment">
                                <ManagerComment title={'Manager Comment'} note={note} onNoteChange={handleNoteChange} />
                                <ManagerComment title={'Client Partner Comment'} note={clientNote} onNoteChange={handleClientChange} />
                                <NeedsAttentionBox
                                    title={'Needs Management Discussions'}
                                    need_attention={needsAttention}
                                    onNeedManagementChange={handleNeedManagementChange}
                                />
                                <RiskLevelBox title={'Risk Level'} colorCode={riskLevel} onRiskLevelChange={handleRiskLevelChange} />
                            </div>
                            {isLoadingQuestionsData ? (
                                <LoadingComponent />
                            ) : (
                                questions.map((question, index) => {
                                    const sectionId = `section-${index + 1}`;
                                    const tag = `Q ${index + 1}`;
                                    const data = { tag, question };
                                    return (
                                        <div key={index} id={sectionId}>
                                            <ReviewBox question={data} onUpdateAnswer={updateAnswer} />
                                        </div>
                                    );
                                })
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '800px',
                                    textAlign: 'center',
                                    marginTop: '20px',
                                }}
                            >
                                <div
                                    style={{
                                        position: 'fixed',
                                        top: 110,
                                        right: 20,
                                    }}
                                >
                                    <GreenButtonED
                                        iconSrc={submitArrow}
                                        disabled={!isFormDirty || isSaving}
                                        buttonText="Submit"
                                        width="206px"
                                        height="44px"
                                        fontSize={14}
                                        lineHeight={18}
                                        type="button"
                                        onClick={handleSaveClick}
                                        style={{ flex: '0 0 auto' }}
                                    />
                                    {isSaving && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: Green600,
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-70px',
                                            }}
                                        />
                                    )}
                                </div>
                            </Box>
                        </Box>
                    </Box>
                    {error?.message && (
                        <SnackBar open={true} message={error.message} severity={error.severity} handleClose={() => setError(null)} />
                    )}
                    {success && (
                        <SnackBar
                            open={true}
                            message="Form submitted successfully!"
                            severity="success"
                            handleClose={handleSuccessSnackbarClose}
                        />
                    )}
                </React.Fragment>
            )}
        </WrapperPage>
    );
};
export default ProjectReviewPage;
