import { useState, useEffect } from 'react';
import axiosFetch from '../api/Axios';
import { ENDPOINTS } from '../api/endpoints';
import { useSnackbar } from '../hooks/useSnackbar';

export const useQuestions = () => {
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const { showSnackbar } = useSnackbar();

    useEffect(() => {
        const getQuestions = async () => {
            try {
                const response = await axiosFetch({
                    method: 'GET',
                    url: ENDPOINTS.QUESTIONS.LIST_ACTIVE,
                });
                setQuestions(response);
            } catch (error) {
                const message = error.response?.status === 404 
                    ? 'Questions not found' 
                    : 'Error fetching questions';
                showSnackbar(message, 'error');
                setQuestions([]);
            } finally {
                setLoading(false);
            }
        };

        getQuestions();
    }, [showSnackbar]);

    return {
        questions,
        loading
    };
}; 