import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosFetch from '../../api/Axios';
import WrapperPage from '../../components/WrapperPage';
import { Box, Avatar, Typography, Card, CardContent, Button, Tab } from '@mui/material';
import { TabPanel, TabList, TabContext } from '@mui/lab';
import SeeProjectDetailsStyles from './SeeProjectDetailsStyles';
import { ArchiveIconRed, ClientIcons, ReviewIcon, StartDateGreenIcon } from '../../assets/icons';
import { Blue400, White100, RedAlert, Blue800, Grey900, Green600 } from '../../consts/colors';
import { formatDate } from '../../utils/dateUtils';
import StatusChip from '../../components/StatusChip';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import { tabsData as getTabsData } from './utils';
import PromptUser from '../../components/promt/PromptUser';
import { ArchiveProjectIcon } from '../../assets/icons';
import SnackBar from '../../components/SnackBar';
import LoadingComponent from '../../components/LoadingComponent';
import ActionItems from '../../components/actionItems/ActionItems';

const SeeProjectDetails = ({ sidebarWidth }) => {
    const { itemId } = useParams();
    const [dataChart, setDataChart] = useState(null);
    const [questions, setQuestionsData] = useState();
    const [lastReviewId, setLastReviewId] = useState(null);
    const [projectDetails, setProjectDetails] = useState([]);
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState('1');
    const [isLoading, setIsLoading] = useState(true);
    const [isPromptVisible, setPromptVisible] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [users, setUsers] = useState([]);
    const hasData = dataChart?.length > 0;

    useEffect(() => {
        fetchProjectDetailsData();
        getReviews();
        fetchUsers();
    }, [itemId]);

    const fetchUsers = async () => {
        axiosFetch({
            method: 'GET',
            url: process.env.REACT_APP_API_PORT + 'users?limit=all',
        }).then((res) => setUsers(res));
    };

    const fetchProjectDetailsData = async () => {
        try {
            setIsLoading(true);
            setError(null);
            
            const [projectResponse, usersResponse] = await Promise.all([
                axiosFetch({
                    method: 'GET',
                    url: process.env.REACT_APP_API_PORT + `project/${itemId}`,
                }),
                axiosFetch({
                    method: 'GET',
                    url: process.env.REACT_APP_API_PORT + 'users',
                })
            ]);

            setProjectDetails(projectResponse);
            setUsers(usersResponse);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if (error.response?.status === 404) {
                setError('Project not found');
            } else {
                setError('Error loading project details');
            }
        }
    };

    const updateReview = async (updatedReview) =>
        axiosFetch({
            url: process.env.REACT_APP_API_PORT + 'review/' + projectDetails.review_id,
            method: 'put',
            data: updatedReview,
        });

    const getReviews = async () => {
        try {
            const reviewsResponse = await axiosFetch({
                method: 'GET',
                url: `${process.env.REACT_APP_API_PORT}project/${itemId}/reviews`,
            });

            if (!reviewsResponse || reviewsResponse.length === 0) {
                console.warn('No reviews found for the project');
                return;
            }

            const { review_id } = reviewsResponse[0];
            setLastReviewId(review_id);

            const lastReviewResponse = await axiosFetch({
                method: 'GET',
                url: `${process.env.REACT_APP_API_PORT}review/${review_id}`,
            });
            if (lastReviewResponse?.question_answer) {
                setQuestionsData(lastReviewResponse.question_answer);
            } else {
                console.warn('Last review data is incomplete or missing');
            }

            setDataChart(reviewsResponse);
        } catch (error) {
            console.error('Error fetching reviews:', error.message);
        }
    };

    const handleArchiveProject = async (projectId) => {
        const updatedProject = {
            is_archived: true,
        };

        const options = {
            url: process.env.REACT_APP_API_PORT + 'project/' + projectId,
            method: 'put',
            data: updatedProject,
        };

        try {
            const responseData = await axiosFetch(options);
            if (responseData) {
                setSuccess(true);
                navigate('/projects');
            } else setSuccess(false);
        } catch (error) {
            setError({
                message: error.message,
                severity: 'error',
            });
        }
    };

    const handleFieldSave = async (newValue, fieldIdentifier) => {
        updateReview({
            [fieldIdentifier]: newValue,
        })
            .then(() => {
                let key = fieldIdentifier;
                switch (fieldIdentifier) {
                    case 'managerComment':
                        key = 'manager_comment';
                        break;
                    case 'clientPartnerComment':
                        key = 'client_partner_comment';
                        break;
                    default:
                        break;
                }
                setProjectDetails({ ...projectDetails, [key]: newValue });
            })
            .catch((err) => console.log(err));
    };

    const handleSuccessSnackbarClose = () => {
        setSuccess(false);
    };

    const renderDetailsCards = (title, avatar, value) => {
        return (
            <Box>
                <Typography sx={SeeProjectDetailsStyles.details}>{title}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {avatar ? (
                        avatar
                    ) : (
                        <Avatar variant="circle" sx={{ ...SeeProjectDetailsStyles.avatarCircle, width: 25, height: 25 }}>
                            <Typography
                                variant="subtitle1"
                                color="inherit"
                                sx={{ ...SeeProjectDetailsStyles.typographyPr, fontSize: '9px' }}
                            >
                                {value
                                    ?.split(' ')
                                    .map(function (str) {
                                        return str ? str[0].toUpperCase() : '';
                                    })
                                    .slice(0, 2)
                                    .join('')}
                            </Typography>
                        </Avatar>
                    )}
                    <Typography
                        sx={{
                            ...SeeProjectDetailsStyles.titleDescription,
                            fontSize: '15px',
                            padding: '0px',
                            marginLeft: '5px',
                            color: Grey900,
                        }}
                    >
                        {value}
                    </Typography>
                </Box>
            </Box>
        );
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const showPrompt = () => {
        setPromptVisible(true);
    };
    const tabsData = getTabsData({
        projectDetails,
        handleFieldSave,
        hasData,
        users,
        projectId: itemId,
        questions: questions
    });

    if (isLoading) {
        return (
            <WrapperPage sidebarWidth={sidebarWidth}>
                <LoadingComponent />
            </WrapperPage>
        );
    }

    if (error) {
        return (
            <WrapperPage sidebarWidth={sidebarWidth}>
                <Box 
                    display="flex" 
                    flexDirection="column" 
                    alignItems="center" 
                    justifyContent="center" 
                    height="80vh"
                    gap={3}
                >
                    <Typography variant="h5" color={Blue800}>
                        {error}
                    </Typography>
                    <Button 
                        variant="contained" 
                        onClick={() => navigate('/projects')}
                        sx={{
                            bgcolor: Blue800,
                            color: 'white',
                            '&:hover': {
                                bgcolor: Blue800,
                            }
                        }}
                    >
                        Back to Projects
                    </Button>
                </Box>
            </WrapperPage>
        );
    }

    return (
        <WrapperPage sidebarWidth={sidebarWidth}>
            <Box sx={SeeProjectDetailsStyles.main}>
                <Box sx={SeeProjectDetailsStyles.lastReviewWidth}>
                    <Card elevation={3} sx={SeeProjectDetailsStyles.lastReviewCard}>
                        <CardContent>
                            <Box sx={SeeProjectDetailsStyles.titleBox} display="flex" justifyContent="space-between" alignItems="center">
                                <Box sx={SeeProjectDetailsStyles.headerBox}>
                                    <Avatar variant="square" sx={SeeProjectDetailsStyles.avatarProject}>
                                        <Typography variant="subtitle1" color="inherit" sx={SeeProjectDetailsStyles.typographyPr}>
                                            {projectDetails?.name
                                                ?.split(' ')
                                                .map(function (str) {
                                                    return str ? str[0].toUpperCase() : '';
                                                })
                                                .slice(0, 2)
                                                .join('')}
                                        </Typography>
                                    </Avatar>
                                    <Typography sx={SeeProjectDetailsStyles.projectName}>{`${projectDetails.name}`}</Typography>
                                    <StatusChip status={projectDetails.risk_level} />
                                </Box>
                                <Box>
                                    <Button
                                        onClick={showPrompt}
                                        startIcon={<ArchiveIconRed sx={{ width: '16px', height: '16px' }} />}
                                        sx={{ ...SeeProjectDetailsStyles.button, border: 'none', color: RedAlert, fontWeight: 600 }}
                                        style={{ marginRight: '10px' }}
                                    >
                                        Archive
                                    </Button>
                                    <Button
                                        onClick={() => navigate(`/view-edit/${itemId}`)}
                                        startIcon={
                                            <ModeEditOutlineRoundedIcon color={Blue800} sx={{ width: '16px', height: '16px' }} />
                                        }
                                        sx={SeeProjectDetailsStyles.button}
                                        style={{ marginRight: '10px' }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        onClick={() => navigate(`/review-project/${itemId}`)}
                                        startIcon={<ReviewIcon color={Blue800} sx={{ width: '16px', height: '16px' }} />}
                                        sx={{ ...SeeProjectDetailsStyles.button, backgroundColor: Blue400, color: 'white' }}
                                    >
                                        Review
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '50px', margin: '10px' }}>
                                {renderDetailsCards('Client', <ClientIcons />, projectDetails.client_name)}
                                {renderDetailsCards('Start Date', <StartDateGreenIcon />, formatDate(projectDetails.start_date))}
                                {renderDetailsCards('Manager', null, projectDetails.ro_first_name + ' ' + projectDetails.ro_last_name)}
                                {renderDetailsCards(
                                    'Client Partner',
                                    null,
                                    projectDetails.cp_first_name + ' ' + projectDetails.cp_last_name
                                )}
                                {renderDetailsCards(
                                    'Director of Engineering',
                                    null,
                                    projectDetails.doe_first_name + ' ' + projectDetails.doe_last_name
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            {/*Tabs*/}
            <Box sx={{ width: '99%', mt: 3 }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={handleTabChange}
                            aria-label="Project Details Tabs"
                            TabIndicatorProps={{ style: { backgroundColor: Green600 } }}
                        >
                            {tabsData.map((tab) => (
                                <Tab
                                    sx={{
                                        textTransform: 'capitalize',
                                        fontWeight: tabValue === tab.value ? '600' : 'normal',
                                        '&.Mui-selected': {
                                            color: Grey900,
                                        },
                                    }}
                                    key={tab.value}
                                    label={tab.label}
                                    value={tab.value}
                                />
                            ))}
                        </TabList>
                    </Box>
                    {tabsData.map((tab) => (
                        <TabPanel key={tab.value} value={tab.value} sx={{ padding: 0 }}>
                            {tab.content}
                        </TabPanel>
                    ))}
                </TabContext>
            </Box>
            {isPromptVisible && (
                <PromptUser
                    title="Confirm Archiveing the Project"
                    actionName="Archive project"
                    action={handleArchiveProject}
                    actionId={itemId}
                    setPromptVisible={setPromptVisible}
                    icon={<ArchiveProjectIcon width={'20px'} height={'20px'} color={White100} />}
                />
            )}
            {error?.message && (
                <SnackBar open={true} message={error.message} severity={error.severity} handleClose={() => setError(null)} />
            )}
            {success && (
                <SnackBar open={true} message="Project updated successfully!" severity="success" handleClose={handleSuccessSnackbarClose} />
            )}
        </WrapperPage>
    );
};

export default SeeProjectDetails;
