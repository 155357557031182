import { Box, Card, Typography, Avatar, Divider, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import SeeProjectDetailsStyles from './SeeProjectDetailsStyles';
import { Grey900 } from '../../consts/colors';
import { ThreeDots } from '../../assets/icons';
import { formatDate } from '../../utils/dateUtils';
import EditIcon from "../../assets/icons/editIcon.svg";

const CommentsCard = ({ data }) => {
    return (
        <Card sx={SeeProjectDetailsStyles.commentsCard}>
            <Box sx={SeeProjectDetailsStyles.commentsCardHeader}>
                <Box sx={SeeProjectDetailsStyles.headerBox}>
                    <Avatar variant="circle" sx={{ ...SeeProjectDetailsStyles.avatarCircle, width: 25, height: 25 }}>
                        <Typography variant="subtitle1" color="inherit" sx={{ ...SeeProjectDetailsStyles.typographyPr, fontSize: '9px' }}>
                            {data?.manager_name
                                ?.split(' ')
                                .map((str) => (str ? str[0].toUpperCase() : ''))
                                .slice(0, 2)
                                .join('')}
                        </Typography>
                    </Avatar>
                    <Typography
                        sx={{
                            ...SeeProjectDetailsStyles.titleDescription,
                            fontSize: '15px',
                            padding: '0px',
                            marginLeft: '5px',
                            color: Grey900,
                        }}
                    >
                        {data?.manager_name}
                    </Typography>
                </Box>
                <Link
                    component={RouterLink}
                    to={`/view-review/${data.review_id}`}
                    sx={{
                        textDecoration: 'none',
                        color: 'inherit',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                >
                    <Typography sx={{ ...SeeProjectDetailsStyles.projectStatusDefaultText }}>
                        {formatDate(data.date)}
                        <img src={EditIcon} alt="Edit" height={16} width={16} marginLeft={50} style={{marginLeft: '5px'}} />
                        
                    </Typography>
                </Link>
                {/* <Typography sx={{ ...SeeProjectDetailsStyles.projectStatusDefaultText, fontWeight: 400 }}>
                    
                </Typography> */}
            </Box>
            <Divider sx={{ marginTop: '5px', marginBottom: '5px' }} />
            <Box className="main">
                <Typography sx={{ ...SeeProjectDetailsStyles.projectStatusDefaultText, fontWeight: 40, whiteSpace: 'pre-wrap' }}>{data?.comment}</Typography>
            </Box>
        </Card>
    );
};

export default CommentsCard;
