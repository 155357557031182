import { Black100, Blue100, Blue800, White100, Blue400, Black400, Black800, Grey600, White800, Grey50 } from '../../consts/colors';

const SeeProjectDetailsStyles = {
    headerBox: {
        display: 'flex',
        alignItems: 'center',
    },

    projectStatusInlineBoxes: {
        display: 'flex',
        paddingRight: '10px',
        justifyContent: 'space-between',
    },

    headerLeftBox: {
        display: 'flex',
        alignItems: 'center', // Center items vertically
        gap: '16px', // Add some space between items
    },
    avatarProject: {
        width: 40,
        height: 40,
        backgroundColor: Blue100,
        borderRadius: '5px',
    },
    avatarCircle: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: Blue100,
    },
    graphText: {
        color: '#585858',
        position: 'relative',
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'center',
    },
    projectStatusDefaultText: {
        color: Blue800,
        fontWeight: 600,
        fontSize: 'small',
        padding: '2px',
        margin: 0,
    },
    selectField: {
        width: '100px', // Adjust width to fit content
        height: '30px', 
        marginLeft: '0px',
        border: 'none',
        background: 'none',
        boxShadow: 'none',
        padding: '0',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none', // Remove default border
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: 'none', // Remove hover border
        },
        '& .MuiSelect-select': {
          display: 'flex',
          alignItems: 'center',
          padding: '0 8px', // Adjust padding
          gap: '4px', // Space between circle and text
        },
    },
    typographyPr: {
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: '14px',
    },
    projectName: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '43.2px',
        letter: '-2.5%',
        color: Blue800,
        marginLeft: '7px',
    },
    projectDetailsBox: {
        marginTop: '0.8%',
        marginLeft: '10px',
    },
    details: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18.2px',
        color: Black100,
    },
    main: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '30px',
        marginLeft: '0px',
        marginRight: '0px',
    },

    projectStatusCardWidth: {
        width: '40%',
        marginBottom: 'auto',
    },
    actionItemsCardWidth: {
        width: '59%',
    },

    lastReviewWidth: {
        width: '100%',
    },

    lastReviewCard: {
        borderRadius: '10px',
        backgroundColor: White100,
        marginRight: '2%',
    },

    description: {
        height: '180px',
        marginBottom: '2%',
        borderRadius: '10px',
        backgroundColor: White100,
    },
    reviewOwner: {
        height: '180px',
        borderRadius: '10px',
        marginBottom: '2%',
        backgroundColor: White100,
    },
    status: {
        height: '450px',
        marginBottom: '2%',
        borderRadius: '10px',
        backgroundColor: White100,
        overflowY: 'auto',
    },
    rhistory: {
        minHeight: '450px',
        borderRadius: '10px',
        backgroundColor: White100,
        marginBottom: '2%',
    },
    titleDescription: {
        paddingBottom: '15px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: 'larger',
        lineHeight: '28.8px',
        color: Black800,
        letter: '-1.5%',
        marginLeft: '5px',
    },
    inlineStatusFields: {
        padding: '6px',
        display: 'block',
        width: '100%',
        paddingRight: '16px',
    },
    mainStatusField: {
        padding: '2px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: 'small',
        color: Grey600,
    },
    statusFields: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: 'small',
        color: Blue800,
        display: '-webkit-box',
        WebkitLineClamp: 2,
        lineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        padding: '2px',
    },
    titleBox: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    statusBox: {
        display: 'flex',
        width: '24px',
        height: '24px',
        borderRadius: '8px',
    },
    statusText: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '23.4px',
        letter: '-1%',
        color: Blue400,
        marginLeft: '10px',
        marginTop: '2px',
        alignItems: 'center',
    },
    sBox: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        width: '100px',
        height: '40px',
        borderRadius: '8px',
        border: '2px solid ' + Blue800,
        backgroundColor: 'none',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '18px',
        letterSpacing: '-0.005em',
        textAlign: 'left',
        color: Blue800,
        '&:hover': {
            backgroundColor: 'White',
        },
        textTransform: 'capitalize',
    },
    buttonEditLastReview: {
        marginRight: '10px',
    },
    buttonEdit: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '18.2px',
        letterSpacing: '-0.5%',
        textTransform: 'capitalize',
        color: Black400,
        '&:hover': { backgroundColor: 'transparent' },
        marginLeft: '8px',
    },
    tooltipTitle: {
        fontFamily: 'Poppins',
        fontWeight: 'regular',
        fontSize: '14px',
        color: White100,
        transition: 'background-color 0.3s, color 0.3s',
    },
    // Comments Card Styles
    commentsCard: {
        borderRadius: '8px',
        backgroundColor: White100,
        margin: '0px 1% 2% 1%',
        padding: '10px',
        border: '1px solid',
        borderColor: White800,
    },
    commentsCardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '40px',
        backgroundColor: Grey50,
    },
    iconMenuSettings: {
        width: '15px',
        height: '15px',
        color: Black800,
        backgroundColor: 'none',
    },
};

export default SeeProjectDetailsStyles;
