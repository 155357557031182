import React, { useState, useEffect, useContext } from 'react';
import WrapperPage from '../../components/WrapperPage';
import GreenButton from '../../components/GreenButton';
import { useNavigate } from 'react-router-dom';
import ListProjectComponent from '../../components/listProjectCards/ListProjectComponent';
import SearchBar from '../../components/SearchBar';
import { fetchProjectsData } from '../../components/listProjectCards/getProjects';
import FilterIcon from '../../assets/icons/filter.svg';
import FilterButton from '../../components/filter/FilterButton';
import axiosFetch from '../../api/Axios';
import SnackBar from '../../components/SnackBar';
import { CurrentUserContext } from '../../App';
import { CREATE_PERMISSION } from '../../consts/constants';
import { Green600, PrimaryGreen } from '../../consts/colors';
import { Plus, ArrowDown } from '../../assets/icons';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { ToggleButton, ToggleButtonGroup, Tooltip, Modal, Button, Box } from '@mui/material';
import TableProjectsComponent from '../../components/listProjectCards/TableProjectsComponent';
import { useLocalStorage } from '../../utils/LocalStorageUtils';
import { filterProjects, createCSVFile } from './utils';
import { ProjectsPageStyle } from './ProjectsPageStyle';
import UploadFileComponent from '../../components/UploadFileComponent';

function ProjectsPage({ sidebarWidth }) {
    const [projects, setProjects] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [sortType, setSortType] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const { currentUser } = useContext(CurrentUserContext);
    const [projectsView, setProjectsView] = useLocalStorage('projectsView', 'table');
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = React.useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);

    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setUploadedFile(null);
        setOpenModal(false);
    };

    useEffect(() => {
        fetchProjectsData()
            .then(async (response) => {
                setProjects(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleSuccessSnackbarClose = () => {
        setSuccess(false);
    };

    const handleArchiveProject = async (projectId) => {
        const updatedProject = {
            is_archived: true,
        };

        const options = {
            url: process.env.REACT_APP_API_PORT + 'project/' + projectId,
            method: 'put',
            data: updatedProject,
        };

        try {
            const responseData = await axiosFetch(options);
            if (responseData) {
                setSuccess(true);
                setProjects(projects.filter((p) => p.project_id !== projectId));
            } else setSuccess(false);
        } catch (error) {
            setError({
                message: error.message,
                severity: 'error',
            });
        }
    };

    const handleDownload = () => {
        const csvContent = createCSVFile(projects);
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'GM% Bulk Upload Template.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFlagClick = async (projectId, flag) => {
        let updatedProject;
        flag === true ? (updatedProject = { needs_attention: false }) : (updatedProject = { needs_attention: true });

        const options = {
            url: process.env.REACT_APP_API_PORT + 'project/' + projectId,
            method: 'put',
            data: updatedProject,
        };

        try {
            const responseData = await axiosFetch(options);
            if (responseData) setSuccess(true);
            else setSuccess(false);
            fetchProjectsData()
                .then(async (response) => {
                    setProjects(response);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        } catch (error) {
            console.log(error);
            setError({
                message: error.message,
                severity: 'error',
            });
        }
    };

    const handleSearchChange = (value) => {
        setSearchValue(value);
    };

    const nav = useNavigate();

    const handleUpdateProjects = async () => {
        const formData = new FormData();
        formData.append('file', uploadedFile);
        try {
            await axiosFetch({
                url: process.env.REACT_APP_API_PORT + 'projects/update-gross-margins',
                data: formData,
                method: 'put',
                contentType: 'multipart/form-data',
            });

            setOpenModal(false);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handleFileUpload = async (file) => {
        setUploadedFile(file);
    };

    const handleFilter = (value) => {
        setSortType(value);
    };

    const canCreateProject = currentUser?.permissions?.includes(CREATE_PERMISSION);

    const filteredProjects = filterProjects(projects, searchValue, sortType, currentUser);

    return (
        <WrapperPage title="View Projects" sidebarWidth={sidebarWidth}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '36px',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
                    <SearchBar onSearchChange={handleSearchChange} placeholder={'Search projects...'} />
                    <div style={{ marginLeft: '25px' }}>
                        <FilterButton title="Filter Projects" icon={FilterIcon} sortType={handleFilter} reviewOption={true} />
                    </div>
                    <div style={{ marginLeft: '25px' }}>
                        <ToggleButtonGroup
                            value={projectsView}
                            exclusive
                            onChange={(ev, view) => {
                                if (view !== null) {
                                    setProjectsView(view);
                                }
                            }}
                            aria-label="Projects View"
                        >
                            <ToggleButton value="table" aria-label="Table View">
                                <Tooltip title="Table View">
                                    <TableRowsIcon />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton value="columns" aria-label="Column View">
                                <Tooltip title="Column View">
                                    <ViewColumnIcon />
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
                <div>
                    {/* {currentUser?.role?.toLowerCase() === 'admin' && (
                        <React.Fragment>
                            <GreenButton
                                iconSrc={<Plus width={'20px'} height={'20px'} />}
                                buttonText="Bulk Upload GM%"
                                width="212px"
                                height="44px"
                                fontSize={14}
                                lineHeight={18}
                                backgroundColor={Green600}
                                backgroundColorHover={PrimaryGreen}
                                onClick={() => {
                                    handleOpenModal();
                                }}
                            />
                            <Modal
                                open={openModal}
                                onClose={handleCloseModal}
                                aria-labelledby="parent-modal-title"
                                aria-describedby="parent-modal-description"
                            >
                                <Box sx={{ ...ProjectsPageStyle.modalStyle, width: 800 }}>
                                    <h2 id="parent-modal-title">Upload Bulk Gross Margin Data</h2>
                                    <UploadFileComponent onFileUpload={handleFileUpload} />
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: '16px',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <GreenButton
                                            iconSrc={<ArrowDown width={'20px'} height={'20px'} />}
                                            buttonText="Download Template"
                                            width="212px"
                                            height="44px"
                                            fontSize={14}
                                            lineHeight={18}
                                            backgroundColor={Green600}
                                            backgroundColorHover={PrimaryGreen}
                                            onClick={handleDownload}
                                        />

                                        <GreenButton
                                            iconSrc={<Plus width={'20px'} height={'20px'} />}
                                            buttonText="Load Data"
                                            width="212px"
                                            height="44px"
                                            fontSize={14}
                                            lineHeight={18}
                                            disabled={!uploadedFile}
                                            backgroundColor={Green600}
                                            backgroundColorHover={PrimaryGreen}
                                            onClick={handleUpdateProjects}
                                        />
                                    </div>
                                </Box>
                            </Modal>
                        </React.Fragment>
                    )} */}
                    {canCreateProject && (
                        <GreenButton
                            iconSrc={<Plus width={'20px'} height={'20px'} />}
                            buttonText="Create New Project"
                            width="212px"
                            height="44px"
                            fontSize={14}
                            lineHeight={18}
                            backgroundColor={Green600}
                            backgroundColorHover={PrimaryGreen}
                            onClick={() => {
                                nav('/newproject');
                            }}
                        />
                    )}
                </div>
            </div>
            {projectsView === 'table' ? (
                <TableProjectsComponent
                    projects={filteredProjects}
                    currentUser={currentUser}
                    isLoading={isLoading}
                    handleArchiveProject={handleArchiveProject}
                />
            ) : (
                <ListProjectComponent
                    projects={filteredProjects}
                    handleArchiveProject={handleArchiveProject}
                    handleFlagClick={handleFlagClick}
                />
            )}
            {error?.message && (
                <SnackBar open={true} message={error.message} severity={error.severity} handleClose={() => setError(null)} />
            )}

            {success && (
                <SnackBar open={true} message="Project updated successfully!" severity="success" handleClose={handleSuccessSnackbarClose} />
            )}
        </WrapperPage>
    );
}

export default ProjectsPage;
