import React from 'react';
import { Chip } from '@mui/material';
import { Green300, Yellow800, RedAlert } from '../consts/colors';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import AccessAlarmsRoundedIcon from '@mui/icons-material/AccessAlarmsRounded';

const StatusChip = ({ status }) => {
    const options = [
        { value: 0, label: 'Green', color: Green300, textColor: '#fff' },
        { value: 1, label: 'Needs Attention', color: Yellow800, textColor: '#121212', icon: <ErrorRoundedIcon sx={{ color: '#121212 !important', width: '15px', height: '15px' }} /> },
        { value: 2, label: 'Fix Now', color: RedAlert, textColor: 'white', icon: <AccessAlarmsRoundedIcon sx={{ color: 'white !important', width: '15px', height: '15px' }} /> },
    ];

    const currentOption = options.find(option => option.value === status);

    if (!currentOption) {
        return null;
    }

    return (
        status !== 0 &&
            <Chip
                label={currentOption.label}
                style={{
                    backgroundColor: currentOption.color,
                    color: currentOption.textColor,
                    fontWeight: 600, 
                    marginLeft: '15px',
                    borderRadius: '8px',
                }}
                icon={currentOption.icon || null}
            />
    );
};

export default StatusChip;
