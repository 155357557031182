import React, { useContext, useState } from 'react';
import { Box, Button, CardContent, Typography } from '@mui/material';
import { CurrentUserContext } from '../../App';
import SeeProjectDetailsStyles from '../../pages/SeeProjectDetails/SeeProjectDetailsStyles';
import LoadingComponent from '../LoadingComponent';
import ActionItem from './ActionItem';
import { AddNoBackgroundIcon } from '../../assets/icons';
import { Black400, Green400 } from '../../consts/colors';
import ActionItemModal from './ActionItemModal';
import { useActionItems } from '../../hooks/useActionItems';
import { useQuestions } from '../../hooks/useQuestions';
import { useSnackbar } from '../../hooks/useSnackbar';
import SnackBar from '../SnackBar';

export default function ActionItems({ users, projectId }) {
    const [showClosedActionItems, setShowClosedActionItems] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useContext(CurrentUserContext);
    const { snackbarText, snackbarSeverity, isOpen, hideSnackbar } = useSnackbar();

    const {
        actionItems,
        openActionItems,
        closedActionItems,
        loading: actionItemsLoading,
        addActionItem,
        updateActionItem,
        deleteActionItem,
    } = useActionItems(projectId);

    const { questions, loading: questionsLoading } = useQuestions();

    const handleAddActionItem = async (formData) => {
        const success = await addActionItem(formData, currentUser.id);
        if (success) {
            setShowModal(false);
        }
    };

    return (
        <CardContent style={{ height: 450 }}>
            <SnackBar open={isOpen} message={snackbarText} severity={snackbarSeverity} handleClose={hideSnackbar} />
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={SeeProjectDetailsStyles.titleDescription}>Action Items</Typography>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    {closedActionItems.length > 0 && !showClosedActionItems && (
                        <Button
                            variant="text"
                            onClick={() => setShowClosedActionItems(true)}
                            sx={{
                                ...SeeProjectDetailsStyles.projectStatusDefaultText,
                                ...SeeProjectDetailsStyles.selectField,
                                color: Green400,
                                textTransform: 'capitalize',
                            }}
                        >
                            Show All
                        </Button>
                    )}
                    {closedActionItems.length > 0 && showClosedActionItems && (
                        <Button
                            variant="text"
                            onClick={() => setShowClosedActionItems(false)}
                            sx={{
                                ...SeeProjectDetailsStyles.projectStatusDefaultText,
                                ...SeeProjectDetailsStyles.selectField,
                                color: Green400,
                                textTransform: 'capitalize',
                            }}
                        >
                            Hide Closed
                        </Button>
                    )}
                    <Button
                        onClick={() => setShowModal(true)}
                        startIcon={<AddNoBackgroundIcon color={Black400} sx={{ width: '16px', height: '16px' }} />}
                        sx={{ ...SeeProjectDetailsStyles.button, border: 'none', color: Green400, fontWeight: 600 }}
                        style={{ marginRight: '10px' }}
                    >
                        Add item
                    </Button>
                </Box>
            </Box>

            <Box style={{ height: '100%', overflowY: 'auto', paddingBottom: 32 }}>
                {(actionItemsLoading || questionsLoading) && <LoadingComponent style={{ marginTop: 'auto' }} />}
                {!actionItemsLoading && actionItems.length === 0 && (
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                        <Typography sx={SeeProjectDetailsStyles.graphText}>No Action Items Available</Typography>
                    </Box>
                )}
                {!actionItemsLoading && actionItems.length !== 0 && openActionItems.length === 0 && !showClosedActionItems && (
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                        <Typography sx={SeeProjectDetailsStyles.graphText}>No Open Action Items Available</Typography>
                    </Box>
                )}
                {!actionItemsLoading && (
                    <>
                        {showClosedActionItems &&
                            actionItems.map((item) => (
                                <ActionItem
                                    key={item.action_item_id}
                                    item={item}
                                    users={users}
                                    questions={questions}
                                    updateItem={updateActionItem}
                                    deleteItem={deleteActionItem}
                                />
                            ))}
                        {!showClosedActionItems &&
                            openActionItems.map((item) => (
                                <ActionItem
                                    key={item.action_item_id}
                                    item={item}
                                    users={users}
                                    questions={questions}
                                    updateItem={updateActionItem}
                                    deleteItem={deleteActionItem}
                                />
                            ))}
                    </>
                )}
            </Box>
            <ActionItemModal
                isOpen={showModal}
                closeModal={() => setShowModal(false)}
                users={users}
                questions={questions}
                saveActionItem={handleAddActionItem}
            />
        </CardContent>
    );
}
