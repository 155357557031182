import { useState, useCallback } from 'react';

export const useSnackbar = () => {
    const [snackbarText, setSnackbarText] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [isOpen, setIsOpen] = useState(false);

    const showSnackbar = useCallback((message, severity = 'info') => {
        setSnackbarText(message);
        setSnackbarSeverity(severity);
        setIsOpen(true);
    }, []);

    const hideSnackbar = useCallback(() => {
        setIsOpen(false);
    }, []);

    return {
        snackbarText,
        snackbarSeverity,
        isOpen,
        showSnackbar,
        hideSnackbar
    };
}; 