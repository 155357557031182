const BASE_API_URL = process.env.REACT_APP_API_PORT;

export const ENDPOINTS = {
    ACTION_ITEMS: {
        LIST: (projectId) => `${BASE_API_URL}action_items/project/${projectId}`,
        CREATE: `${BASE_API_URL}action_item`,
        UPDATE: (id) => `${BASE_API_URL}action_item/${id}`,
        DELETE: (id) => `${BASE_API_URL}action_item/${id}`,
    },
    QUESTIONS: {
        LIST: `${BASE_API_URL}questions`,
        LIST_ALL: `${BASE_API_URL}questions?limit=all`,
        LIST_ACTIVE: `${BASE_API_URL}questions/active`,
    },
    USERS: {
        LIST: `${BASE_API_URL}users`,
        LIST_ALL: `${BASE_API_URL}users?limit=all`,
    }
}; 