import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box,
    FormControl,
    Select,
    MenuItem,
    Typography,
    Avatar,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Blue800, Green600, White100, Blue100, Green50 } from '../../consts/colors';
import { styles } from './styles';

const defaultFormState = {
    assignee_id: '',
    due_date: null,
    question_id: '',
    comment: '',
};

export default function ActionItemModal({
    isOpen,
    closeModal,
    saveActionItem,
    users = [],
    questions = [],
    initialData = null,
    isEditing = false,
}) {
    const [formData, setFormData] = useState(defaultFormState);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (isOpen && initialData) {
            setFormData({
                description: initialData.description || '',
                assignee_id: initialData.assignee_id || '',
                due_date: initialData.due_date ? dayjs(initialData.due_date) : null,
                question_id: initialData.question_id || '',
                comment: initialData.comment || '',
            });
        } else {
            setFormData(defaultFormState);
        }
        setErrors({});
    }, [isOpen, initialData]);

    const handleChange = (field) => (event) => {
        const value = event.target.value;
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
        if (errors[field]) {
            setErrors((prev) => ({
                ...prev,
                [field]: null,
            }));
        }
    };

    const handleDateChange = (date) => {
        setFormData((prev) => ({
            ...prev,
            due_date: date,
        }));
        if (errors.due_date) {
            setErrors((prev) => ({
                ...prev,
                due_date: null,
            }));
        }
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.assignee_id) {
            newErrors.assignee_id = 'Assignee is required';
        }

        if (!formData.due_date) {
            newErrors.due_date = 'Due date is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = () => {
        if (!validateForm()) return;

        const submitData = {
            ...formData,
            due_date: formData.due_date ? dayjs(formData.due_date).format('YYYY-MM-DD') : null,
        };

        saveActionItem(submitData);
        closeModal();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={closeModal}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: '16px',
                    padding: '24px',
                    backgroundColor: White100,
                },
            }}
        >
            <DialogTitle sx={{ p: 0, mb: 3 }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontFamily: 'Poppins',
                        fontSize: '24px',
                        fontWeight: 600,
                        color: Blue800,
                    }}
                >
                    {isEditing ? 'Edit Action Item' : 'New Action Item'}
                </Typography>
            </DialogTitle>

            <DialogContent sx={{ p: 0 }}>
                <Box display="flex" flexDirection="column" gap={3}>
                    <Box display="flex" gap={2}>
                        <FormControl fullWidth error={!!errors.assignee_id}>
                            <Typography sx={{ fontSize: '14px', fontWeight: 500, color: Blue800, mb: 1 }}>Assignee</Typography>
                            <Select
                                value={formData.assignee_id}
                                onChange={handleChange('assignee_id')}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return <Typography sx={{ color: 'text.disabled' }}>Select Person</Typography>;
                                    }
                                    const user = users.find((u) => u.user_id === selected);
                                    return (
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Avatar
                                                sx={{
                                                    width: 28,
                                                    height: 28,
                                                    bgcolor: Green600,
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {user?.first_name?.[0]}
                                                {user?.last_name?.[0]}
                                            </Avatar>
                                            <Typography>{`${user?.first_name} ${user?.last_name}`}</Typography>
                                        </Box>
                                    );
                                }}
                                sx={{
                                    height: '42px',
                                    borderRadius: '8px',
                                    backgroundColor: White100,
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: 300,
                                            '& .MuiMenuItem-root': {
                                                padding: '12px',
                                                '&:hover': {
                                                    backgroundColor: Green50,
                                                },
                                            },
                                        },
                                    },
                                }}
                            >
                                {users.map((user) => (
                                    <MenuItem key={user.user_id} value={user.user_id}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Avatar
                                                sx={{
                                                    width: 28,
                                                    height: 28,
                                                    bgcolor: Green600,
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {user.first_name[0]}
                                                {user.last_name[0]}
                                            </Avatar>
                                            <Box>
                                                <Typography sx={{ fontWeight: 500 }}>{`${user.first_name} ${user.last_name}`}</Typography>
                                                <Typography variant="caption" color="text.secondary">
                                                    {user.role}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.assignee_id && (
                                <Typography color="error" variant="caption">
                                    {errors.assignee_id}
                                </Typography>
                            )}
                        </FormControl>

                        <FormControl fullWidth>
                            <Typography sx={{ fontSize: '14px', fontWeight: 500, color: Blue800, mb: 1 }}>Due Date</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={formData.due_date}
                                    onChange={handleDateChange}
                                    format="DD/MM/YYYY"
                                    
                                    slotProps={{
                                        textField: {
                                            error: !!errors.due_date,
                                            helperText: errors.due_date,
                                            placeholder: "Select Date",
                                            sx: {
                                                '& .MuiOutlinedInput-root': {
                                                    height: '42px',
                                                    borderRadius: '8px',
                                                    backgroundColor: White100,
                                                },
                                            },
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Box>

                    <FormControl fullWidth error={!!errors.question_id}>
                        <Typography sx={{ fontSize: '14px', fontWeight: 500, color: Blue800, mb: 1 }}>Question</Typography>
                        <Select
                            value={formData.question_id}
                            onChange={handleChange('question_id')}
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) {
                                    return <Typography sx={{ color: 'text.disabled' }}>Select Question</Typography>;
                                }
                                const question = questions.find((q) => q.question_id === selected);
                                return question?.value_question;
                            }}
                            sx={{
                                height: '42px',
                                borderRadius: '8px',
                                backgroundColor: White100,
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: 300,
                                        '& .MuiMenuItem-root': {
                                            padding: '12px',
                                            '&:hover': {
                                                backgroundColor: Green50,
                                            },
                                        },
                                    },
                                },
                            }}
                        >
                            {questions.map((question) => (
                                <MenuItem key={question.question_id} value={question.question_id}>
                                    <Typography>{question.value_question}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.question_id && (
                            <Typography color="error" variant="caption">
                                {errors.question_id}
                            </Typography>
                        )}
                    </FormControl>

                    <TextField
                        multiline
                        rows={4}
                        placeholder="Write a comment..."
                        value={formData.comment}
                        onChange={handleChange('comment')}
                        fullWidth
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                                backgroundColor: White100,
                            },
                        }}
                    />
                </Box>
            </DialogContent>

            <DialogActions sx={{ p: 0, mt: 3 }}>
                <Button
                    onClick={closeModal}
                    sx={{
                        color: Blue800,
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                        bgcolor: Green600,
                        color: White100,
                        borderRadius: '8px',
                        '&:hover': {
                            bgcolor: Blue800,
                        },
                    }}
                >
                    {isEditing ? 'Save Changes' : 'Create Item'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
