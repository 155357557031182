import { Box, IconButton } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosFetch from '../../api/Axios';
import EditIcon from '../../assets/icons/editIcon.svg';
import CheckIcon from '../../assets/icons/greenChecked.svg';
import SnackBar from '../../components/SnackBar';
import { useLocalStorage } from '../../utils/LocalStorageUtils';
import RoundCheckbox from '../checkBox/checkBox';
import CustomTable from '../table/Table';
import UserRoleDropdown from '../userRoleDropdown';
import { ListUserStyles } from './styles';

const renderRoleColumn = (user, handleRoleChange) => {
    return <UserRoleDropdown user={user} onRoleChange={handleRoleChange} />;
};

const renderProjectsColumn = (projects) => {
    if (projects && projects.length > 0) {
        const projectNames = projects.map((project) => project.name);
        return <div style={{ minWidth: '200px' }}>{projectNames.join(', ')}</div>;
    } else {
        return <div style={{ minWidth: '200px' }} />;
    }
};

const renderEnableColumn = (user, handleDeactivationChange) => (
    <FormControlLabel
        control={
            <RoundCheckbox
                checked={!user.deactivate_user}
                onChange={(event) => handleDeactivationChange(user.user_id, !event.target.checked)}
                CheckIcon={CheckIcon}
            />
        }
        label={<span style={{ ...ListUserStyles.bodyText }}>{user.deactivate_user ? 'Disabled' : 'Enabled'}</span>}
    />
);

const renderActionsColumn = (user) => (
    <Link to={`/edit-user/${user.user_id}`} style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
        <IconButton>
            <img src={EditIcon} alt="Edit" height={16} width={16} />
            <span style={{ ...ListUserStyles.bodyText, marginLeft: '8px' }}>Edit user</span>
        </IconButton>
    </Link>
);

export default function ListUsers({ data, setData }) {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [paginationModel, setPaginationModel] = useLocalStorage('usersPagination', {
        page: 0,
        pageSize: 100,
    });
    const handleRoleChange = (userId, newRole) => {
        setData((prevData) => prevData.map((item) => (item.user_id === userId ? { ...item, user_role: newRole } : item)));
    };

    const handleDeactivationChange = async (userId, newValue) => {
        try {
            const response = await axiosFetch({
                method: 'PUT',
                url: process.env.REACT_APP_API_PORT + 'user/' + userId,
                data: { deactivate_user: newValue },
            });
            if (response) {
                setSuccess(true);
            }
            setData((prevData) => prevData.map((item) => (item.user_id === userId ? { ...item, deactivate_user: newValue } : item)));
        } catch (error) {
            console.error('Error updating Notification Active:', error);
        }
    };

    const headers = [
        { label: 'First name', key: 'prename', width: '10%' },
        { label: 'Last name', key: 'name', width: '10%' },
        { label: 'Permissions', key: 'role', width: '10%' },
        { label: 'Assigned Projects', key: 'projects', width: '35%' },
        { label: 'Status', key: 'enable', width: '15%' },
        { label: 'Actions', key: 'action', width: '10%' },
    ];

    const rows = data.map((user) => ({
        key: user.user_id,
        name: user.last_name,
        prename: user.first_name,
        role: renderRoleColumn(user, handleRoleChange),
        projects: renderProjectsColumn(user.projects),
        enable: renderEnableColumn(user, handleDeactivationChange),
        action: renderActionsColumn(user),
    }));

    const handleSuccessSnackbarClose = () => {
        setSuccess(false);
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    const fetchProjects = async () => {
        try {
            const projectsData = await axiosFetch({
                method: 'GET',
                url: process.env.REACT_APP_API_PORT + 'projects?limit=all',
            });

            if (projectsData && typeof projectsData === 'object' && Object.keys(projectsData).length > 0) {
                const projectsArray = Object.values(projectsData);
                const projectsByUser = projectsArray.reduce((acc, project) => {
                    const { user_id } = project;
                    if (!acc[user_id]) {
                        acc[user_id] = [];
                    }
                    acc[user_id].push(project);
                    return acc;
                }, {});

                setData((prevData) =>
                    prevData.map((item) => ({
                        ...item,
                        projects: projectsByUser[item.user_id] || [],
                    }))
                );
            } else {
                console.error('No projects data found or invalid format.');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Box sx={{ width: '100%', marginTop: '20px', marginRight: '36px' }}>
            <CustomTable
                paginationModel={paginationModel}
                onPaginationModelChanged={setPaginationModel}
                headers={headers}
                rows={rows}
                isMarginTopRequired={true}
                goToReviewPageNeeded={false}
            />
            {success && (
                <SnackBar
                    open={true}
                    message="User's status changed successfully!"
                    severity="success"
                    handleClose={handleSuccessSnackbarClose}
                />
            )}
            {error?.message && (
                <SnackBar open={true} message={error.message} severity={error.severity} handleClose={() => setError(null)} />
            )}
        </Box>
    );
}
