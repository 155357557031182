import { createTheme } from '@mui/material';
import { Blue800, Grey50, Grey600, White400, Blue400, Blue100 } from '../../consts/colors';

export const autocompleteFilterStyles = createTheme({
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    minWidth: '250px',
                    borderRadius: '8px',
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '18px',
                    letterSpacing: '-0.005em',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        backgroundColor: 'inherit',
                        border: `2px solid ${Blue800}`,
                        '&:hover': {
                            backgroundColor: 'inherit',
                            borderColor: Blue800,
                        },
                        '&.Mui-focused': {
                            borderColor: Blue800,
                            boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)',
                        },
                        '& input': {
                            padding: '10px 12px',
                        },
                    },
                },
                inputRoot: {
                    paddingRight: '10px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: 'inherit',
                },
                notchedOutline: {
                    border: 'none',
                },
            },
        },
    },
   
});

export const styles = {
    boxModal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "642px",
        bgcolor: "white",
        borderRadius: "8px",
        boxShadow: 24,
        p: 4,
        padding: "30px",
      },
      title: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "28.8px",
        letter: "-1.5%",
        marginBottom: "20px",
        marginLeft: "15px",
      },
      label: {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "small",
        lineHeight: "23.4px",
        letter: "-1%",
        color: Grey600,
      },
      button: {
        width: '100px',
        height: '40px',
        borderRadius: '8px',
        border: '2px solid ' + Blue800,
        backgroundColor: 'none',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '18px',
        letterSpacing: '-0.005em',
        textAlign: 'left',
        color: Blue800,
        textTransform: 'capitalize',
    },
    avatarCircle: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: Blue100,
    },
    typographyPr: {
        fontFamily: 'Poppins',
        fontWeight: 'regular',
        fontSize: '14px',
    },
    typographyMenuOptions:{
        fontFamily: 'Poppins',
        fontWeight:"regular",
        fontSize: '14px',
        color: Blue400,
        marginLeft: '10px',
      },
};