import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import { ArrowBack, ArrowForward } from '../../assets/icons';
import styles, { StyledTableCell } from './style';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <ArrowForward /> : <ArrowBack />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === 'rtl' ? <ArrowBack /> : <ArrowForward />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function CustomTable({
    headers,
    rows,
    isMarginTopRequired,
    goToReviewPageNeeded,
    paginationModel,
    onPaginationModelChanged,
    getRowStyle,
    onRowClick,
    defaultSort,
    defaultSortOrder = 'asc',
}) {
    const [page, setPage] = useState(paginationModel?.page || 0);
    const [rowsPerPage, setRowsPerPage] = useState(paginationModel?.pageSize || 5);
    const [orderBy, setOrderBy] = useState(defaultSort ? [defaultSort] : []);
    const [order, setOrder] = useState(defaultSort ? [defaultSortOrder] : []);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        if (onPaginationModelChanged) onPaginationModelChanged({ ...paginationModel, page: newPage });
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        if (onPaginationModelChanged) onPaginationModelChanged({ pageSize: newRowsPerPage, page: 0 });
    };

    const handleSort = (key) => {
        const existingIndex = orderBy.indexOf(key);
        let newOrderBy = [...orderBy];
        let newOrder = [...order];

        if (existingIndex !== -1) {
            // If column is already sorted, toggle order or remove it
            if (newOrder[existingIndex] === 'asc') {
                newOrder[existingIndex] = 'desc';
            } else {
                newOrderBy.splice(existingIndex, 1);
                newOrder.splice(existingIndex, 1);
            }
        } else {
            // Add new column for sorting
            newOrderBy.push(key);
            newOrder.push('asc');
        }

        setOrderBy(newOrderBy);
        setOrder(newOrder);
    };

    useEffect(() => {
        if (page > 0 && rows.length < rowsPerPage) {
            setPage(0);
            if (onPaginationModelChanged) onPaginationModelChanged({ ...paginationModel, page: 0 });
        }
    }, [rows]);

    const sortedRows = React.useMemo(() => {
        if (orderBy.length === 0) return rows;

        return [...rows].sort((a, b) => {
            for (let i = 0; i < orderBy.length; i++) {
                const key = orderBy[i];
                const sortOrder = order[i];

                if (a[key] < b[key]) return sortOrder === 'asc' ? -1 : 1;
                if (a[key] > b[key]) return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }, [rows, orderBy, order]);

    const paginatedRows =
        paginationModel.pageSize === -1 ? sortedRows : sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Box sx={isMarginTopRequired ? [{ marginTop: '30px', width: '98.5%' }] : [{ marginTop: '0px', width: '98.5%' }]}>
            <Table
                sx={{
                    borderCollapse: 'separate',
                    borderSpacing: '0 5px',
                    border: 'none',
                    maxWidth: '100%',
                    overflowX: 'auto',
                }}
                aria-label="custom pagination table"
            >
                <TableHead>
                    <TableRow style={{ ...styles.row, height: '44px' }}>
                        {headers.map((header) => (
                            <StyledTableCell
                                style={{ ...styles.header, width: header.width, cursor: header.sortable ? 'pointer' : 'default' }}
                                key={header.key}
                                onClick={header.sortable ? () => handleSort(header.key) : undefined}
                            >
                                {header.label}
                                {header.sortable && (
                                    <Box component="span" sx={{ marginLeft: '8px', display: 'inline-flex' }}>
                                        {orderBy.includes(header.key) && (order[orderBy.indexOf(header.key)] === 'asc' ? '↑' : '↓')}
                                    </Box>
                                )}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paginatedRows.map((row, rowIndex) => (
                        <TableRow
                            key={row.key ?? `${page}${rowIndex}`}
                            style={{
                                ...styles.row,
                                height: '44px',
                                cursor: goToReviewPageNeeded || onRowClick ? 'pointer' : 'default',
                                ...(getRowStyle ? getRowStyle(row) : {}),
                            }}
                            onClick={
                                goToReviewPageNeeded
                                    ? () => (window.location.href = `/view-review/${row['review_id']}`)
                                    : onRowClick
                                    ? () => onRowClick(row)
                                    : undefined
                            }
                        >
                            {headers.map((header) => (
                                <StyledTableCell
                                    key={`${header.key}_${rowIndex}`}
                                    component="th"
                                    scope="row"
                                    style={{
                                        ...styles.data,
                                        width: header.width,
                                    }}
                                >
                                    {header.renderCell ? header.renderCell(row) : row[header.key]}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <StyledTableCell colSpan={headers.length} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            style={styles.footer}
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={headers.length}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </Box>
    );
}

CustomTable.propTypes = {
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            width: PropTypes.string,
            sortable: PropTypes.bool,
            renderCell: PropTypes.func,
        })
    ).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    isMarginTopRequired: PropTypes.bool,
    goToReviewPageNeeded: PropTypes.bool,
    getRowStyle: PropTypes.func,
    paginationModel: PropTypes.object,
    onPaginationModelChanged: PropTypes.func,
    onRowClick: PropTypes.func,
    defaultSort: PropTypes.string,
    defaultSortOrder: PropTypes.string,
};

export default CustomTable;
