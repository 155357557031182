import React, { useContext, useState, useEffect, memo } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Link,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styles } from './LastReviewStyles';
import axiosFetch from "../../api/Axios";
import { AddNoBackgroundIcon } from '../../assets/icons';
import { Black400, Green400 } from '../../consts/colors';
import ActionItemModal from '../actionItems/ActionItemModal';
import { useActionItems } from '../../hooks/useActionItems';
import ViewReviewIcon from '../../assets/icons/ViewReviewIcon.svg';
import { CurrentUserContext } from '../../App';
import SnackBar from '../SnackBar';
import SeeProjectDetailsStyles from '../../pages/SeeProjectDetails/SeeProjectDetailsStyles';

const LastReview = memo(({ users, projectId }) => {
  const [questions, setQuestions] = useState([]);
  const [expandedQuestion, setExpandedQuestion] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [lastReviewId, setLastReviewId] = useState(null);
  const { currentUser } = useContext(CurrentUserContext);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedQuestion(isExpanded ? panel : false);
  };

  const handleSuccessSnackbarClose = () => {
    setSuccess(false);
  };

  const { addActionItem } = useActionItems(projectId);
  
  const handleAddActionItem = async (formData) => {
    const success = await addActionItem(formData, currentUser.id);
    if (success) {
        setShowModal(false);
        setSuccess(true);
        fetchLastReview();
    }
};

  const getRowStyle = (row) => {
    if (row.score === 0) {
      // N/A
      return { border: '2px solid gray', backgroundColor: 'rgba(236, 240, 244, 0.2)' };
    } else if (row.score < 3) {
        return { border: '2px solid red', backgroundColor: 'rgba(255, 0, 0, 0.2)' };
    } else if (row.score >= 3 && row.score < 4) {
        return { border: '2px solid yellow', backgroundColor: 'rgba(255, 255, 0, 0.2)' };
    // } else if (row.score >= 4) {
        // return { border: '2px solid green', backgroundColor: 'rgba(0, 255, 0, 0.2)' };
    }
    return {};
};

  const fetchLastReview = async () => {
    if (!projectId) {
      setError('No project ID provided');
      return;
    }

    try {
      // Reset error state at the start of the fetch
      setError(null);

      // Get all reviews for the project
      const reviews = await axiosFetch({
        url: `${process.env.REACT_APP_API_PORT}project/${projectId}/reviews`,
        method: "GET"
      });
      
      if (!reviews || reviews.length === 0) {
        setQuestions([]);
        return;
      }

      const latestReview = reviews[0];

      setLastReviewId(latestReview.review_id);
      
      // Get the latest review details
      const reviewData = await axiosFetch({
        url: `${process.env.REACT_APP_API_PORT}review/${latestReview.review_id}`,
        method: "GET"
      });
      
      if (!reviewData?.question_answer?.length) {
        setQuestions([]);
        return;
      }

      // Get action items for the project
      const actionItems = await axiosFetch({
        url: `${process.env.REACT_APP_API_PORT}action_items/project/${projectId}`,
        method: "GET",
        validateStatus: function (status) {
          return status < 500; // Resolve only if the status code is less than 500
        }
      });

      // Create a map of action items by question ID
      const actionItemsByQuestion = actionItems ? actionItems.reduce((acc, item) => {
        if (item.question_id && item.status === 'Open') {
          acc[item.question_id] = acc[item.question_id] || [];
          acc[item.question_id].push(item);
        }
        return acc;
      }, {}) : {};
      
      // Format questions with action items
      const formattedQuestions = reviewData.question_answer.map((qa) => ({
        id: qa.question_id,
        question_id: qa.question_id,
        question: qa.question || qa.value_question,
        value_question: qa.question || qa.value_question,
        response: qa.response || qa.value_response,
        quick_note: qa.quick_note || '',
        actionItemsCount: actionItemsByQuestion[qa.question_id]?.length || 0,
        actionItems: actionItemsByQuestion[qa.question_id],
        notes: qa.quick_note ? 1 : 0,
        subCategory: qa.question_sub_category || '',
        score: qa.response_score || 0
      }));
      
      setQuestions(formattedQuestions);
    } catch (error) {
      console.error('Error in fetchLastReview:', error);
      setError(error.response?.data?.message || 'Error loading review data');
      setQuestions([]);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchLastReview();
    }
    return () => {
      // Cleanup on unmount or when projectId changes
      setQuestions([]);
      setExpandedQuestion(false);
      setError(null);
    };
  }, [projectId]);

  if (error) {
    return (
      <Box sx={styles.container}>
        <Card elevation={0} sx={styles.card}>
          <CardContent sx={styles.content}>
            <Typography color="error" sx={styles.noDataText}>
              {error}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      <Card elevation={0} sx={styles.card}>
        <CardContent sx={styles.content}>
          <Typography sx={styles.title}>
            Last Review
          </Typography>
          <Button
              onClick={() => setShowModal(true)}
              startIcon={<AddNoBackgroundIcon color={Black400} sx={{ width: '16px', height: '16px' }} />}
              sx={{ ...SeeProjectDetailsStyles.button, border: 'none', color: Green400, fontWeight: 600 }}
              style={{ marginRight: '10px', width: '150px' }}
          >
              Add Action item
          </Button>
          <Link to={`/view-review/${lastReviewId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <IconButton href={`/view-review/${lastReviewId}`}>
                  <img src={ViewReviewIcon} alt="View Review" height={16} width={16} />
                  <Typography sx={{ marginLeft: '8px', fontSize: '14px', color: '#2D3748' }}>
                      View Review
                  </Typography>
              </IconButton>
          </Link>
          {questions.length === 0 ? (
            <Typography sx={styles.noDataText}>
              No review data available
            </Typography>
          ) : (
            <Box sx={styles.questionsContainer}>
              {questions.map((question, index) => (
                <Accordion
                  key={question.id}
                  expanded={expandedQuestion === `question${question.id}`}
                  onChange={handleAccordionChange(`question${question.id}`)}
                  sx={styles.questionAccordion}
                  elevation={0}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={styles.questionAccordionSummary}
                    style={getRowStyle(question)}
                  >
                    <Box sx={styles.questionContainer}>
                      <Typography sx={styles.questionText}>
                        {question.question}
                      </Typography>
                      <Box sx={styles.metricsContainer}>
                        <Typography sx={styles.questionText}>
                          {question.response}
                        </Typography>
                      </Box>
                      <Box sx={styles.metricsContainer}>
                        <Typography sx={styles.metricLabel}>
                          Action Items
                        </Typography>
                        <Box sx={{
                          ...styles.metricAvatar,
                          ...(question.actionItemsCount > 0 ? styles.activeMetricAvatar : styles.inactiveMetricAvatar)
                        }}>
                          {question.actionItemsCount}
                        </Box>
                      </Box>
                      <Box sx={styles.metricsContainer}>
                        <Typography sx={styles.metricLabel}>
                          Notes
                        </Typography>
                        <Box sx={{
                          ...styles.metricAvatar,
                          ...(question.notes > 0 ? styles.activeMetricAvatar : styles.inactiveMetricAvatar)
                        }}>
                          {question.notes}
                        </Box>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={styles.accordionDetails}>
                    <Box sx={styles.detailsContainer}>
                      {question.score && (
                        <Typography sx={styles.scoreText}>
                          Score: {question.score}
                        </Typography>
                      )}
                      {question.quick_note && (
                        <Typography sx={styles.noteText}>
                          Note: {question.quick_note}
                        </Typography>
                      )}
                      {question.actionItemsCount > 0 && (
                        <Typography sx={styles.noteText}>
                          <b>Action Items</b>
                        </Typography>
                      )}
                      {question.actionItemsCount > 0 && (
                        question.actionItems.map((actionItem, index) => (
                          <Typography sx={styles.responseText}
                            key={actionItem.id}>
                            <b>{actionItem.assignee_first_name} {actionItem.assignee_last_name}</b> [<b>Due</b>: {(new Date(actionItem.due_date)).toDateString()}]: {actionItem.comment}
                          </Typography>
                        ))
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          )}
        </CardContent>
      </Card>
      <ActionItemModal
          isOpen={showModal}
          closeModal={() => setShowModal(false)}
          users={users}
          questions={questions}
          saveActionItem={handleAddActionItem}
      />
      {success && (
          <SnackBar open={true} message="Action Item added successfully!" severity="success" handleClose={handleSuccessSnackbarClose} />
      )}
    </Box>
  );
});

LastReview.displayName = 'LastReview';

export default LastReview;
