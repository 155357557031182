import ExecutiveSummary from './tabs/ExecutiveSummary';
import LastReview from '../../components/LastReview/LastReview';
import ReviewHistory from '../../components/ReviewHistory/ReviewHistory';

export const tabsData = ({
  projectDetails,
  handleFieldSave,
  hasData,
  users,
  projectId,
  questions
}) => [
  {
    label: 'Executive Summary',
    value: '1',
    content: (
      <ExecutiveSummary
        projectDetails={projectDetails}
        handleFieldSave={handleFieldSave}
        questions={questions}
        hasData={hasData}
        users={users}
      />
    ),
  },
  { 
    label: 'Last Review', 
    value: '2', 
    content: (
    <LastReview 
      users={users} 
      projectId={projectId} 
      /> )
  },
  { 
    label: 'Review History', 
    value: '3', 
    content: <ReviewHistory projectId={projectId} /> 
  },
  // { label: 'Project Notes', value: '4', content: 'Project Notes Content' },
];

export const defaultValues = {
    risk: 'N/A',
    needs_discussion: 'N/A',
    last_review: 'Never',
    chart: 'No Data Available',
    action_items: 'No Action Items Available',
    mgr: 'Needs Review',
    cp: 'Needs Review',
};
