import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import axiosFetch from '../../api/Axios';
import ViewReviewIcon from '../../assets/icons/ViewReviewIcon.svg';
import { formatDate } from '../../utils/dateUtils';
import CustomTable from '../../components/table/Table';
import { renderStatusColumn } from '../../utils/TableUtils';

const renderReviewOwnerColumn = (user_id, reviewOwnerIDs) => {
    const owners = reviewOwnerIDs[user_id];
    if (owners && owners.length > 0) {
        return owners.map((user) => (
            <div key={user.user_id}>
                {user.first_name} {user.last_name}
            </div>
        ));
    }
    return 'N/A';
};

const renderActionsColumn = (review_id) => (
    <Link to={`/view-review/${review_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <IconButton>
            <img src={ViewReviewIcon} alt="View Review" height={16} width={16} />
            <Typography sx={{ marginLeft: '8px', fontSize: '14px', color: '#2D3748' }}>
                View Review
            </Typography>
        </IconButton>
    </Link>
);

const ReviewHistory = ({ projectId }) => {
    const [reviewOwnerIDs, setReviewOwnerIDs] = useState([]);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const headers = [
        { label: 'Review Number', key: 'review_id', width: '20%' },
        { label: 'Date completed', key: 'created_date', width: '20%' },
        { label: 'Project Status', key: 'status', width: '20%' },
        { label: 'Manager', key: 'user_id', width: '20%' },
        { label: 'Actions', key: 'actions', width: '20%' },
    ];

    const rows = data.map((item) => ({
        review_id: item.review_id,
        created_date: formatDate(item.created_date),
        status: renderStatusColumn(item.status),
        user_id: renderReviewOwnerColumn(item.user_id, reviewOwnerIDs),
        actions: renderActionsColumn(item.review_id),
    }));

    useEffect(() => {
        async function fetchReviewsData() {
            try {
                const [reviewsResponse, usersResponse] = await Promise.all([
                    axiosFetch({
                        method: 'GET',
                        url: `${process.env.REACT_APP_API_PORT}project/${projectId}/reviews`,
                    }),
                    axiosFetch({
                        method: 'GET',
                        url: `${process.env.REACT_APP_API_PORT}users`,
                    })
                ]);

                if (reviewsResponse && reviewsResponse.length > 0) {
                    setData(reviewsResponse);
                    
                    // Create a map of user IDs to user details
                    const userMap = {};
                    reviewsResponse.forEach(review => {
                        if (review.user_id) {
                            const reviewer = usersResponse.find(user => user.user_id === review.user_id);
                            if (reviewer) {
                                userMap[review.user_id] = [reviewer];
                            }
                        }
                    });
                    setReviewOwnerIDs(userMap);
                }
            } catch (error) {
                setError(error.message);
                console.error('Error fetching review data:', error);
            }
        }

        if (projectId) {
            fetchReviewsData();
        }
    }, [projectId]);

    return (
        <Box sx={{ p: 2 }}>
            <CustomTable
                headers={headers}
                rows={rows}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                rowCount={data.length}
            />
        </Box>
    );
};

export default ReviewHistory;
